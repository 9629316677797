import { Outlet, Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import Step1 from "../step 1/step1";
import Step2 from "../step2";
import Step3 from "../step3";
import Step4 from "../step4";
import Step5 from "../step 5/step5";
import Step6 from "../step 6/step6";
import Step7 from "../step 7/step7";

import AlertModal from "./AlertModal";

import { useUtilsDashboardView } from "../../Hooks/useUtils";
import { Helmet } from "react-helmet";
import Typography from "@mui/material/Typography";

//Firebase
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyC3DZX6cepyxTZ123d9R9dHhtgl2UzWaTA",
  authDomain: "nft-builder-1ae28.firebaseapp.com",
  projectId: "nft-builder-1ae28",
  storageBucket: "nft-builder-1ae28.appspot.com",
  messagingSenderId: "481888800049",
  appId: "1:481888800049:web:a15f35b03bf0951abe3410",
  measurementId: "G-TH1G84BSGG",
};

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});
export const GeneratorContainer = () => {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const { nftToolMethods, nftToolVaribales } = useUtilsDashboardView();
  const values = nftToolVaribales;

  const RenderStep = () => {
    if (values.step === 1) {
      return <Step1 />;
    }
    if (values.step === 2) {
      return <Step2 />;
    }
    if (values.step === 3) {
      return <Step3 />;
    }
    if (values.step === 4) {
      return <Step4 />;
    }
    if (values.step === 5) {
      return <Step5 />;
    }
    if (values.step === 6) {
      return <Step6 />;
    }
    if (values.step === 7) {
      return <Step7 />;
    }
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: 3,
      }}
    >
      {/* <CheckoutForm /> */}
      <Helmet>
        <style>{"body { background-color: #f0f0f0; }"}</style>
      </Helmet>
      {/* <Elements stripe={stripePromise}> */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={10}>
          <div>
            <Typography variant="h3">
              <b>NFT Builder</b>
            </Typography>
          </div>
          <div>[by LE DEVS]</div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={1}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            onClick={() => {
              nftToolMethods.setContainerModal({ open: true, type: "help" });
            }}
            style={{ textTransform: "none", color: "blue" }}
          >
            <h3>
              <b>Help</b>
            </h3>
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={1}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            onClick={() => {
              nftToolMethods.setContainerModal({ open: true, type: "exit" });
            }}
            style={{ textTransform: "none" }}
            color="error"
          >
            <h3>
              <b>Exit</b>
            </h3>
          </Button>
        </Grid>
        <AlertModal />
        <RenderStep />
      </Grid>
    </Box>
  );
};

export default GeneratorContainer;
