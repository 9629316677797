import { BodyText, PaperNormal } from "../../../../common/components";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
};

const paperStyle = {
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  backgroundColor: "#26282E",
};

export const HelpModal = () => {
  return (
    <Grid container spacing={2} style={style}>
      <Grid item xs={12}>
        <PaperNormal style={paperStyle}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3
                style={{
                  display: "inline",
                  color: "#FCFCFC",
                  fontWeight: "bold",
                }}
              >
                We Are Happy To Help!
              </h3>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p style={{ display: "inline", color: "#FCFCFC" }}>
                Please email us at{" "}
                {<b style={{ color: "orange" }}> leDevsinfo@gmail.com</b>} with
                your name and a detailed message on how we can assit you.
              </p>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <b style={{ display: "inline", color: "#FCFCFC" }}>
                All questions are welcomed!
              </b>
            </Grid>
          </Grid>
        </PaperNormal>
      </Grid>
    </Grid>
  );
};
export default HelpModal;
