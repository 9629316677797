import Paper from "@mui/material/Paper";
import CardContent from "@mui/material/CardContent";

export const PaperNormal = ({ children, style }) => {
  return (
    <Paper elevation={1} style={{ borderRadius: 10, ...style }}>
      <CardContent>{children}</CardContent>
    </Paper>
  );
};

export default PaperNormal;
