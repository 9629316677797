import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  // step 7
  containerModal: false,
  modalType: "",
};

const ContainerReducerSlice = createSlice({
  name: "ContainerReducerSlice",
  initialState: INITIAL_STATE,
  reducers: {
    setContainerModal: (state, action) => {
      let type = action.payload.type;
      let open = action.payload.open;
      state.containerModal = open;
      state.modalType = type;
    },
  },
  extraReducers: {},
});

export const con_toolActions = ContainerReducerSlice.actions;
export const con_toolReducer = ContainerReducerSlice.reducer;
