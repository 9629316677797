import React from "react";
import Typography from "@mui/material/Typography";

export const BodyText = ({ children, style }) => (
  <Typography variant="body1" component="div" style={{ ...style }}>
    {children}
  </Typography>
);

export default BodyText;
