import { useEffect, useState } from "react";
import { RadioButtonGroup } from "./RadioButtonGroup";
import { useUtilsStep7 } from "../../Hooks/step7Utils";
import { useUtilNft } from "../../Hooks/generateNfts";
import { GuardSpinner } from "react-spinners-kit";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { GenerateLoadModal } from "./generateLoadModal";

import CardForm from "../payment/Card.jsx";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";

import { useSelector } from "react-redux";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";

import {
  BodyText,
  Header,
  PaperNormal,
  TextH6,
  PayButton,
  RequiredTF,
} from "../../../../common";
//Firebase
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyC3DZX6cepyxTZ123d9R9dHhtgl2UzWaTA",
  authDomain: "nft-builder-1ae28.firebaseapp.com",
  projectId: "nft-builder-1ae28",
  storageBucket: "nft-builder-1ae28.appspot.com",
  messagingSenderId: "481888800049",
  appId: "1:481888800049:web:a15f35b03bf0951abe3410",
  measurementId: "G-TH1G84BSGG",
};
const stripePromise = loadStripe(
  "pk_live_51I5gTNFx5rZdm2QHuDInhuqH7fyjcfSUEnVKikCX6sVRi92plNzoh1nHGgYoK429N0GsXFF13OfVXWuG04B98eSp00HXIwckOu"
);

export const Step7 = () => {
  const { main, preview } = useUtilNft();
  const { nftToolMethods, nftToolVaribales } = useUtilsStep7();

  const snackBarOpen = useSelector(
    (state) => state.generationReducer.snackBarOpen
  );
  const snackBarMessage = useSelector(
    (state) => state.generationReducer.snackBarMessage
  );

  const values = nftToolVaribales;
  const methods = nftToolMethods;

  const [customerName, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [orderType, setOrderType] = useState("");

  const [createPreview, setCreatePreview] = useState(true);

  useEffect(() => {
    if (values.previewImages.length <= 1) {
      let payload = {
        name: values.name,
        description: values.description,
        number: values.number,
        preView: "step7",
      };
      preview(payload);
    }
  }, [
    values.description,
    values.name,
    values.number,
    preview,
    values.previewImages,
    createPreview,
  ]);

  const calculatePrice = () => {
    return Math.ceil(values.number / 100) * 1.69;
  };
  const checkTextFields = () => {
    console.log("CHECK TEXT");
    if (customerName === "") {
      methods.setSnackBar({
        open: true,
        message: "Please fill out your full name",
      });
      return false;
    }
    if (email === "") {
      methods.setSnackBar({
        open: true,
        message: "Please fill in your email",
      });
      return false;
    } else {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!validRegex.test(email)) {
        methods.setSnackBar({
          open: true,
          message: "Please provide a valid email",
        });
        return false;
      }
    }

    if (phone === "") {
      methods.setSnackBar({
        open: true,
        message: "Please input your phone number",
      });
      return false;
    } else {
      var phoneno =
        /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
      // if (!phoneno.test(phone.replace(/\s+/g, ""))) {
      if (!matchIsValidTel(phone)) {
        methods.setSnackBar({
          open: true,
          message: "Please provide a valid phone number",
        });
        return false;
      }
    }

    return true;
  };
  const renderOrderSection = () => {
    if (orderType === "Free") {
      console.log("Free rendered");
      return (
        <>
          <Grid item xs={12}>
            <BodyText>
              Limited to 100 NFTS per order. Includes watermark.
            </BodyText>
          </Grid>
          <Grid item xs={12}>
            <PayButton
              onClick={() => {
                let p = {
                  type: "free-package",
                  number: values.number > 100 ? 100 : values.number,
                };

                if (checkTextFields()) {
                  main(p);
                }
              }}
            >
              Generate
            </PayButton>
          </Grid>
        </>
      );
    } else if (orderType === "Paid") {
      return (
        <>
          <Grid item xs={12}>
            <BodyText>
              NFT'S will not inlcude a watermark and they will come with
              metadata
            </BodyText>

            <CardForm
              checkTextFields={checkTextFields}
              customerName={customerName}
              email={email}
              phone={phone}
            />
          </Grid>
        </>
      );
    }
    return <></>;
  };
  return (
    <>
      <Elements stripe={stripePromise}>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Header>
            <b>Step 7</b>
          </Header>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <PaperNormal style={{ maxWidth: "50vw" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <BodyText style={{ fontWeight: "bold" }}>
                  Download your NFT collection!
                </BodyText>
              </Grid>
            </Grid>
          </PaperNormal>
        </Grid>
        <Grid
          container
          item
          xs={12}
          spacing={5}
          style={{
            display: "flex",
            justifyContent: "center",
            margin: 15,
          }}
        >
          <Grid item xs={7}>
            <PaperNormal>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h1 style={{ fontWeight: "bold" }}>Order Summary</h1>
                </Grid>
                <Grid item xs={12}>
                  <TextH6 style={{ fontWeight: "bold" }}>
                    Collection Name
                  </TextH6>
                  <BodyText style={{ color: "grey", fontWeight: "bold" }}>
                    {values.name}
                  </BodyText>
                </Grid>
                <Grid item xs={12}>
                  <TextH6 style={{ fontWeight: "bold" }}>
                    Collection Description
                  </TextH6>
                  <BodyText style={{ color: "grey", fontWeight: "bold" }}>
                    {values.description}
                  </BodyText>
                </Grid>
                <Grid item xs={12}>
                  <TextH6 style={{ fontWeight: "bold" }}>
                    Collection Size
                  </TextH6>
                  <BodyText style={{ color: "grey", fontWeight: "bold" }}>
                    {values.number}
                  </BodyText>
                </Grid>
                <Grid item xs={12}>
                  <h3
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "inline",
                      textAlign: "center",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    Price:{" "}
                    {
                      <h3
                        style={{
                          display: "inline",
                          color: "orange",
                        }}
                      >
                        $1.69 for every 100 nfts
                      </h3>
                    }{" "}
                  </h3>
                </Grid>
                <Grid item xs={12}>
                  <h3
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "inline",
                      textAlign: "center",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    Total:
                    {
                      <h3
                        style={{
                          display: "inline",
                          color: "green",
                        }}
                      >
                        {` $${calculatePrice()}`}
                      </h3>
                    }{" "}
                  </h3>
                </Grid>
                <Grid item xs={12}>
                  <h1 style={{ fontWeight: "bold" }}>Order Preview</h1>
                </Grid>

                <Grid item container xs={12}>
                  {/* {values.loading ? (
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 15,
                      }}
                    >
                      <GuardSpinner size={30} color="#686769" loading={true} />
                    </Grid>
                  ) : (
                    <></>
                  )} */}
                  {values.previewImages?.length > 1 ? (
                    <>
                      {values.previewImages.map((preView, num) => {
                        return (
                          <Grid item xs={2}>
                            <div
                              style={{
                                borderRadius: 2,
                                border: "1px dashed black",
                                margin: 8,
                                padding: 4,
                              }}
                            >
                              <img
                                alt={`Preview ${num}`}
                                src={preView}
                                style={{
                                  object: "fit",
                                  width: "100%",
                                  height: "100%",
                                }}
                              />
                            </div>
                          </Grid>
                        );
                      })}
                    </>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 15,
                      }}
                    >
                      <GuardSpinner size={30} color="#686769" loading={true} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </PaperNormal>
          </Grid>
          <Grid item container xs={4}>
            <Grid item xs={12}>
              <PaperNormal>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextH6 style={{ fontWeight: "bold" }}>
                      First & Last Name
                    </TextH6>
                    <RequiredTF
                      id="standard-basic"
                      label="Name"
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      defaultValue={customerName}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextH6 style={{ fontWeight: "bold" }}>Email</TextH6>
                    <RequiredTF
                      id="standard-basic"
                      label="Email"
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      defaultValue={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextH6 style={{ fontWeight: "bold" }}>Phone Number</TextH6>
                    <MuiTelInput
                      value={phone}
                      fullWidth
                      defaultCountry="US"
                      onChange={(e) => {
                        console.log(e);
                        setPhone(e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <RadioButtonGroup p={setOrderType} />
                  </Grid>
                  {renderOrderSection()}
                </Grid>
              </PaperNormal>
            </Grid>
          </Grid>
        </Grid>
        <GenerateLoadModal />
        <Grid
          item
          xs={6}
          style={{ display: "flex", justifyContent: "flex-end", padding: 15 }}
        >
          <Button
            variant="outlined"
            style={{ color: "red" }}
            onClick={() => {
              methods.setStep(6);
            }}
          >
            Back
          </Button>
        </Grid>
        <Grid
          item
          xs={6}
          style={{ display: "flex", justifyContent: "flex-start", padding: 15 }}
        ></Grid>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={snackBarOpen}
          onClose={() => methods.setSnackBarOpen(!snackBarOpen)}
          message={snackBarMessage}
          key={"top-right"}
        />
      </Elements>
    </>
  );
};

export default Step7;
