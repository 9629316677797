import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import * as React from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { BodyText, PaperNormal } from "../../../../common";

import { CubeSpinner, FillSpinner } from "react-spinners-kit";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
};

const paperStyle = {
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  backgroundColor: "#26282E",
};

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="#FCFCFC">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export const GenerateLoadModal = () => {
  const generationModal = useSelector(
    (state) => state.generationReducer.generationModal
  );
  const progress = useSelector((state) => state.generationReducer.progress);
  const engineState = useSelector(
    (state) => state.generationReducer.engineState
  );

  return (
    <>
      <Modal
        open={generationModal}
        // open={false}
        onClose={() => {
          //   setOpenModal(false);
        }}
      >
        <Grid container spacing={2} style={style}>
          <Grid item xs={12}>
            {/* <PaperNormal style={style}> */}
            <PaperNormal style={paperStyle}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <h3
                    style={{
                      display: "inline",
                      color: "#FCFCFC",
                      fontWeight: "bold",
                    }}
                  >
                    NFT Generation Initiated
                  </h3>
                </Grid>

                {progress > 0 ? (
                  <>
                    <Grid
                      item
                      xs={3}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <FillSpinner size={20} color="#686769" loading={true} />
                    </Grid>
                    <Grid
                      item
                      xs={9}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <BodyText style={{ display: "inline", color: "#FCFCFC" }}>
                        {engineState}
                      </BodyText>
                    </Grid>
                    <Grid item xs={12}>
                      <LinearProgressWithLabel
                        color="secondary"
                        value={progress}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CubeSpinner size={30} color="#686769" loading={true} />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <BodyText style={{ display: "inline", color: "#FCFCFC" }}>
                        {engineState}
                      </BodyText>
                    </Grid>
                  </>
                )}
              </Grid>
            </PaperNormal>
          </Grid>
          <Grid item xs={12}>
            <PaperNormal style={paperStyle}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <BodyText
                    style={{
                      display: "inline",
                      color: "#f8dfb0",
                      //   fontWeight: "bold",
                    }}
                  >
                    NOTE: Please do not close or refresh this page until the
                    process completes.
                  </BodyText>
                </Grid>
              </Grid>
            </PaperNormal>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};
