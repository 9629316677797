import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  // step 7
  generationModal: false,
  progress: 0,
  engineState: "",
  snackBarOpen: false,
  snackBarMessage: "",
};

const GenerationReducerSlice = createSlice({
  name: "GenerationReducerSlice",
  initialState: INITIAL_STATE,
  reducers: {
    startMainNftGeneration: (state, action) => {
      console.log("start main ", action.payload);
      if (action.payload === false) {
        state.generationModal = action.payload;
        state.engineState = "";
        state.progress = 0;
      } else {
        state.generationModal = action.payload;
        state.engineState = "Spinning up NFT randomization engine ...";
      }
    },
    setEngineState: (state, action) => {
      state.engineState = action.payload;
    },
    updateProgress: (state, action) => {
      state.progress = action.payload.percent;
      state.engineState = action.payload.message;
    },
    setSnackBarOpen: (state, action) => {
      state.snackBarOpen = action.payload;
    },
    setSnackBarMessage: (state, action) => {
      state.snackBarMessage = action.payload;
    },
    setSnackBar: (state, action) => {
      state.snackBarOpen = action.payload.open;
      state.snackBarMessage = action.payload.message;
    },
  },
  extraReducers: {},
});

export const gen_toolActions = GenerationReducerSlice.actions;
export const gen_toolReducer = GenerationReducerSlice.reducer;
