import Paper from "@mui/material/Paper";
import CardContent from "@mui/material/CardContent";

export const PaperDark = ({ children, style, onClick }) => {
  return (
    <Paper
      onClick={onClick}
      elevation={0}
      style={{ borderRadius: 10, backgroundColor: "#3B3D42", ...style }}
    >
      <CardContent>{children}</CardContent>
    </Paper>
  );
};

export default PaperDark;
