import { combineReducers } from "redux";

import {
  toolReducer,
  gen_toolReducer,
  con_toolReducer,
  con_toolActions,
} from "../../modules/NftTool/reducer";

export const appReducer = combineReducers({
  toolReducer: toolReducer,
  generationReducer: gen_toolReducer,
  containerReducer: con_toolReducer,
});

export default function (state, action) {
  if (action.type === "viewer/setViewer") {
    state = undefined;
    localStorage.removeItem("token");
  }
  return appReducer(state, action);
}
