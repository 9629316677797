import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

import { BodyText, Header, PaperNormal, PaperDark } from "../../../common";
import { useUtilsDashboardView } from "../Hooks/useUtils";

export const Step2 = () => {
  const { nftToolMethods, nftToolVaribales } = useUtilsDashboardView();
  const values = nftToolVaribales;
  const methods = nftToolMethods;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const RenderLayerCards = () => {
    let layerCards;
    if (values.files.length > 0) {
      let layersDict = values.layersDict;
      let layerObjects = [];
      for (const [key, value] of Object.entries(layersDict)) {
        let object = { name: key, assets: value.assets };
        layerObjects.push(object);
      }

      layerCards = layerObjects.map((item, index) => (
        <Grid item xs={4} key={`${item.name} ${index}`}>
          <PaperDark>
            <BodyText style={{ color: "white" }}>
              <b>{item.name}</b>
            </BodyText>
            {/* <aside style={thumbsContainer}>{RenderImages(item.assets)}</aside> */}
            <ImageList gap={10} cols={4} style={{ padding: 2 }}>
              {item.assets.map((i, num) => (
                <ImageListItem
                  key={`${i.name} ${num}`}
                  // style={{ maxWidth: 200, maxHeight: 200 }}
                >
                  <img
                    alt={`${i.name} ${num}`}
                    src={`${i.preview}`}
                    srcSet={`${i.preview}`}
                    loading="lazy"
                    style={{
                      objectFit: "contain",
                      border: "1px dashed black",
                    }}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </PaperDark>
        </Grid>
      ));

      return layerCards;
    }
  };

  return (
    <>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <Header>
          <b>Step 2</b>
        </Header>
      </Grid>
      <Grid
        container
        item
        xs={12}
        spacing={2}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Grid item xs={6} style={{ display: "flex", justifyContent: "center" }}>
          <PaperNormal>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <BodyText>
                  Please confirm that all the layers and images look good. If
                  the folder structure is correct, you should be able to see
                  each layer name and its corresponding assets.
                </BodyText>
              </Grid>
            </Grid>
          </PaperNormal>
        </Grid>

        <Grid
          container
          item
          spacing={5}
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <>
            <RenderLayerCards />
          </>
        </Grid>
        <Grid
          item
          xs={6}
          style={{ display: "flex", justifyContent: "flex-end", padding: 20 }}
        >
          <Button
            variant="outlined"
            style={{ color: "red" }}
            onClick={() => methods.setStep(1)}
          >
            Back
          </Button>
        </Grid>
        <Grid
          item
          xs={6}
          style={{ display: "flex", justifyContent: "flex-start", padding: 20 }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              methods.setStep(3);
            }}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Step2;
