import { useDispatch, useSelector } from "react-redux";

import { toolActions, gen_toolActions } from "../reducer/";

import mergeImages from "merge-images";

const url = require("./L Devs.png");

export const useUtilNft = () => {
  const dispatch = useDispatch();
  const nftToolVaribales = useSelector((state) => state.toolReducer);

  const zip = require("jszip")();
  var FileSaver = require("file-saver");
  var imagesFolder = zip.folder("images");
  var metaData = zip.folder("metaData");

  const weight = (arr) => {
    return [].concat(
      ...arr.map((obj) => Array(Math.ceil(obj.rarity)).fill(obj))
    );
  };

  /**
   *
   * Efficiency Considerations
   *
   * Dont physically generate the space, instead generate
   * a random number and check if it falls in the range of it
   */
  const pick = (arr) => {
    // console.log("Array passed", arr);
    let weighted = weight(arr);
    // console.log("Weighted -> ", weighted, weighted.length);
    return weighted[Math.floor(Math.random() * weighted.length)];
  };

  const createBlockListFrame = (x, assetToLayer) => {
    let dict = {};
    x.forEach((blockedElement) => {
      let layer = assetToLayer[blockedElement];
      if (layer in dict) {
        let layerBlocks = dict[layer];
        dict[layer] = [...layerBlocks, blockedElement];
      } else {
        dict[layer] = [blockedElement];
      }
    });
    return dict;
  };

  const combineBlockFrames = (mainFrame, newFrame) => {
    // console.log("--- Before combine ----", mainFrame, newFrame);
    let result = { ...mainFrame };
    if (Object.keys(newFrame).length !== 0) {
      for (let i = 0; i <= Object.keys(newFrame).length - 1; i++) {
        let layer = Object.keys(newFrame)[i];
        // console.log("Layer ", layer);
        if (!(layer in result)) {
          // add layer
          let newLayerBlock = newFrame[layer];
          result[layer] = newLayerBlock;
        } else {
          // Update layer
          let mainLayerBlocks = result[layer];
          let newLayerBlock = newFrame[layer];
          // console.log("Main blocks ", mainLayerBlocks);
          // console.log("New blocks ", newLayerBlock);
          newLayerBlock.forEach((lb) => {
            if (!mainLayerBlocks.includes(lb)) {
              let current = result[layer];
              current.push(lb);
              result[layer] = current;
            }
          });
        }
      }
      // console.log("---- After combine ----", result);
      return result;
    } else {
      return mainFrame;
    }
  };

  const generateUniqeImageObjects = (x) => {
    console.log("GENERATING IMAGES", x);

    // Array of objects that contains all image data
    let completeImages = [];
    // Dictionary used to check for unique combinations
    let uniqueImages = {};
    // Create asset to layer reference
    let assetToLayer = {};
    // Record repeated combinations
    let repeatedObjects = {};

    let allowRepeat = false;

    let layersDictCopy = {};
    x.number = Number(x.number);
    Object.keys(nftToolVaribales.layersDict).forEach((l) => {
      layersDictCopy[l] = nftToolVaribales.layersDict[l].assets.map((asts) => {
        let qty = Math.ceil((x.number * asts.rarity) / 100);
        return { name: asts.name, rarity: qty, qty: qty, file: asts.file };
      });
    });

    console.log("Dictionary copy ", { ...layersDictCopy });
    // Map Assets to Layer
    nftToolVaribales.arrayOfLayers.forEach((l) => {
      nftToolVaribales.layersDict[l].assets.forEach((asset) => {
        assetToLayer[asset.name] = l;
      });
    });

    let nftsToMake = x.number;
    let repeatCount = 0;
    const startTime = Date.now();
    // START WHILE LOOP PROCESS
    while (nftsToMake > 0) {
      let newOb = {};
      // Create block list frame
      let mainBlockListFrame = {};
      let checkDict = {};
      let objectId = "";

      // initiate block frame
      let bgAsset = pick(layersDictCopy[nftToolVaribales.arrayOfLayers[0]]);
      newOb[nftToolVaribales.arrayOfLayers[0]] = bgAsset;
      checkDict[bgAsset.name] = nftToolVaribales.arrayOfLayers[0];
      objectId += bgAsset.name;
      let bl = nftToolVaribales.blockListObject[bgAsset.name];
      let blockListFrame = createBlockListFrame(bl, assetToLayer);
      mainBlockListFrame = combineBlockFrames(
        mainBlockListFrame,
        blockListFrame
      );

      // Create Image Object
      let shiftedLayers = [...nftToolVaribales.arrayOfLayers];
      shiftedLayers.shift();
      for (let layer of shiftedLayers) {
        let asset;
        // Check if layer has blocked items
        if (layer in mainBlockListFrame) {
          // reduce selection space
          let reducedLayer = [];
          let blockedItems = mainBlockListFrame[layer];
          for (let la of layersDictCopy[layer]) {
            if (!blockedItems.includes(la.name)) {
              reducedLayer.push(la);
            }
          }

          asset = pick(reducedLayer);
        } else {
          asset = pick(layersDictCopy[layer]);
        }

        // If asset seelcted, create the object and update the block list frame
        if (asset) {
          let bl = nftToolVaribales.blockListObject[asset.name];
          let blockListFrame = createBlockListFrame(bl, assetToLayer);
          mainBlockListFrame = combineBlockFrames(
            mainBlockListFrame,
            blockListFrame
          );

          // console.log("------------");
          newOb[layer] = asset;
          checkDict[asset.name] = layer;
          objectId += asset.name;
        }
      }

      if (allowRepeat) {
        if (!(objectId in repeatedObjects)) {
          repeatedObjects[objectId] = 1;
        } else {
          repeatedObjects[objectId] += 1;
        }

        // reduce count per asset
        for (const [layer, asset] of Object.entries(newOb)) {
          layersDictCopy[layer] = layersDictCopy[layer].map((ast) => {
            if (ast.name === asset.name) {
              let astCopy = { ...ast };
              astCopy.rarity -= 1;
              if (astCopy.rarity < 0) {
                astCopy.rarity = 0;
              }
              return astCopy;
            }
            return ast;
          });
        }
        completeImages.push(newOb);
        uniqueImages[objectId] = 1;
        nftsToMake -= 1;
      } else {
        if (!(objectId in uniqueImages)) {
          if (nftsToMake % 1000 === 0) {
            const endTime = Date.now() - startTime;
            console.log(
              "Remaining to be made -< ",
              nftsToMake,
              "Time elapsed for 1,000 nfts ",
              Math.floor(endTime / 100)
            );
            console.log(
              "Check dict ",
              checkDict,
              "Main block list frame ",
              mainBlockListFrame
            );
          }
          // reduce count per asset
          for (const [layer, asset] of Object.entries(newOb)) {
            layersDictCopy[layer] = layersDictCopy[layer].map((ast) => {
              // console.log("ast.name", ast, asset);
              if (ast.name === asset.name) {
                let astCopy = { ...ast };
                astCopy.rarity -= 1;
                if (astCopy.rarity < 0) {
                  astCopy.rarity = 0;
                }
                return astCopy;
              }
              return ast;
            });
          }
          completeImages.push(newOb);
          uniqueImages[objectId] = 1;
          nftsToMake -= 1;
        } else {
          if (repeatCount > 500000) {
            console.log("Repeat limit reached  ", { ...layersDictCopy });
            allowRepeat = true;
          }
          if (repeatCount % 1000 === 0) {
            console.log("Repeat ", { ...layersDictCopy }, repeatCount);
          }
          repeatCount += 1;
        }
      }
    }
    let sum = 0;
    Object.values(repeatedObjects).forEach((i) => (sum += i));
    console.log(
      "Complete images",
      completeImages,
      { ...layersDictCopy },
      "Repeated objects ",
      Object.keys(repeatedObjects).length,
      repeatedObjects,
      sum
    );
    return completeImages;
  };

  const generateImages = async (x, type) => {
    // dispatch(gen_toolActions.setEngineState("Building NFTS"));
    let count = 0;
    let totalCount = x.length;

    console.log("Generating Images");
    for (let item of x) {
      console.log(count);
      let layerOrder = nftToolVaribales.arrayOfLayers;

      const uris = [];
      layerOrder.forEach((layer) => {
        if (layer in item) {
          const i = item[layer];
          uris.push(URL.createObjectURL(i.file));
        }
      });

      // [...uris, { src: url, opacity: 0.85 }]
      let b64Uri;
      let b64;
      // Generate free package
      if (type === "free-package") {
        b64Uri = await mergeImages([...uris, { src: url, opacity: 0.85 }]);
        b64 = b64Uri.replace(/^data:image\/(png|jpg);base64,/, "");
      } else {
        b64Uri = await mergeImages(uris);
        b64 = b64Uri.replace(/^data:image\/(png|jpg);base64,/, "");
      }

      imagesFolder.file(`${count}.png`, b64, { base64: true });
      count += 1;
      if (count % 10 === 0) {
        let percent = (count * 100) / totalCount;
        dispatch(
          gen_toolActions.updateProgress({
            percent: percent,
            message: `Building NFTS ${count} of ${totalCount}`,
          })
        );
      }
    }
    zip.generateAsync({ type: "blob" }).then(function (content) {
      FileSaver.saveAs(content, `${nftToolVaribales.name}.zip`);
      dispatch(gen_toolActions.startMainNftGeneration(false));
    });
  };

  const generateMetaData = (name, x) => {
    // add description to meta data
    // add image and thumbnail
    // create policy id for testing
    // link test image to ipfs
    // add ability to edit files section
    console.log("meta ", x);
    let count = 0;
    let meta = [];
    for (let imageObject of x) {
      let assetName = `${name.trim()}${count}`;
      let metaDataObject = {
        721: {
          b8c67453f06dbb48c97df510c1b91afb38d2a1c2a4f0502bd9f411a1: {},
          version: "1.0",
        },
      };
      metaDataObject["721"][
        "b8c67453f06dbb48c97df510c1b91afb38d2a1c2a4f0502bd9f411a1"
      ][assetName] = {};
      let assetSpot =
        metaDataObject["721"][
          "b8c67453f06dbb48c97df510c1b91afb38d2a1c2a4f0502bd9f411a1"
        ][assetName];
      assetSpot["image"] =
        "ipfs://QmVxmVhcXvRi2dKenDw2qJW2Y9pRtnehpqgxsLAms6j13X";
      for (const [key, value] of Object.entries(imageObject)) {
        assetSpot[key] = value.name;
      }
      metaDataObject["721"][
        "b8c67453f06dbb48c97df510c1b91afb38d2a1c2a4f0502bd9f411a1"
      ][assetName] = assetSpot;
      meta.push(metaDataObject);
      metaData.file(`${count}.json`, JSON.stringify(metaDataObject));
      count += 1;
    }
    return meta;
  };
  const main = async (x) => {
    dispatch(gen_toolActions.startMainNftGeneration(true));
    setTimeout(() => {
      console.log("Time Out completed, setting engine state");
    }, 1000);
    let p = {
      name: nftToolVaribales.name,
      description: nftToolVaribales.description,
      number: nftToolVaribales.number,
      type: x.type,
    };
    if (x.type === "free-package") {
      p.number = p.number > 100 ? 100 : p.number;
    }
    let images = generateUniqeImageObjects(p);
    generateMetaData(p.name, images);
    await generateImages(images, p.type);
  };

  // ----- PREVIEW METHODS --------------------------------
  const generatePreviewImages = async (x) => {
    let count = 0;
    let images = [];
    for (let item of x) {
      // console.log("Image item -> ", item);
      let layerOrder = Object.keys(item);

      const uris = layerOrder.map((layer) => {
        const i = item[layer];
        return URL.createObjectURL(i.file);
      });

      // let waterMark = URL.createObjectURL(url);
      const b64Uri = await mergeImages([...uris, { src: url, opacity: 0.85 }]);
      const b64 = b64Uri.replace(/^data:image\/(png|jpg);base64,/, "");

      images.push(b64Uri);
      count += 1;
    }
    return images;
  };

  const preview = async (x) => {
    console.log("METHOD", x);

    let images;
    let meta;
    if (x.preView === "step6") {
      dispatch(toolActions.startPreviewGen(x));
      let p = { number: 1 };
      images = generateUniqeImageObjects(p);
      meta = generateMetaData(x.name, images);
    } else {
      let p = { number: 6 };
      images = generateUniqeImageObjects(p);
    }
    let previews = await generatePreviewImages(images);
    dispatch(
      toolActions.displayPreview({ previewImages: previews, metaData: meta })
    );
  };

  return {
    generateUniqeImageObjects,
    main,
    preview,
    nftToolVaribales,
  };
};
