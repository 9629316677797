import React from "react";
import icon from "./Space.png";

import { Noise } from "noisejs";
const CANVAS_WIDTH = 3000;
// The amplitude. The amount the noise affects the movement.
const NOISE_AMOUNT = 5;
// The frequency. Smaller for flat slopes, higher for jagged spikes.
const NOISE_SPEED = 0.004;
// Pixels to move per frame. At 60fps, this would be 18px a sec.
const SCROLL_SPEED = 0.3;

const bubbles = [
  { image: 1, s: 0.6, x: 1134, y: 45 },
  { image: 2, s: 0.6, x: 1620, y: 271 },
  { image: 3, s: 0.6, x: 1761, y: 372 },
  { image: 4, s: 0.6, x: 2499, y: 79 },
  { image: 5, s: 0.8, x: 2704, y: 334 },
  { image: 6, s: 0.6, x: 2271, y: 356 },
  { image: 7, s: 0.6, x: 795, y: 226 },
  { image: 8, s: 0.6, x: 276, y: 256 },
  { image: 9, s: 0.6, x: 1210, y: 365 },
  { image: 10, s: 0.6, x: 444, y: 193 },
  { image: 11, s: 0.6, x: 2545, y: 387 },

  { image: 12, s: 0.8, x: 1303, y: 193 },
  { image: 13, s: 0.8, x: 907, y: 88 },
  { image: 14, s: 0.8, x: 633, y: 320 },
  { image: 15, s: 0.8, x: 323, y: 60 },
  { image: 16, s: 0.8, x: 129, y: 357 },
  { image: 17, s: 0.8, x: 1440, y: 342 },
  { image: 18, s: 0.8, x: 1929, y: 293 },
  { image: 19, s: 0.8, x: 2135, y: 198 },
  { image: 20, s: 0.8, x: 2276, y: 82 },
  { image: 21, s: 0.8, x: 2654, y: 182 },
  { image: 22, s: 0.8, x: 2783, y: 60 },
  { image: 23, s: 1.0, x: 1519, y: 118 },
  { image: 24, s: 1.0, x: 1071, y: 233 },
  { image: 25, s: 1.0, x: 1773, y: 148 },
  { image: 26, s: 1.0, x: 2098, y: 385 },
  { image: 27, s: 1.0, x: 2423, y: 244 },
  { image: 28, s: 1.0, x: 901, y: 385 },
  { image: 29, s: 1.0, x: 624, y: 111 },
  { image: 30, s: 1.0, x: 75, y: 103 },
  { image: 31, s: 1.0, x: 413, y: 367 },
  { image: 32, s: 1.0, x: 2895, y: 271 },
  { image: 33, s: 1.0, x: 1990, y: 75 },
];

let backGroundPositions = [];

for (let i = 0; i < 7; i++) {
  for (let j = 0; j < 7; j++) {
    backGroundPositions.push(`${-154 * j}px ${-154 * i}px`);
  }
}

const noise = new Noise();

const BackGround = () => {
  const animationRef = React.useRef(0);
  const bubblesRef = React.useRef(
    bubbles.map((bubble) => ({
      ...bubble,
      noiseSeedX: Math.floor(Math.random() * 64000),
      noiseSeedY: Math.floor(Math.random() * 64000),
      xWithNoise: bubble.x,
      yWithNoise: bubble.y,
    }))
  );

  const [isReady, setReady] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 200);

    animationRef.current = requestAnimationFrame(animate);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, []);

  function animate() {
    bubblesRef.current = bubblesRef.current.map((bubble, index) => {
      const newNoiseSeedX = bubble.noiseSeedX + NOISE_SPEED;
      const newNoiseSeedY = bubble.noiseSeedY + NOISE_SPEED;

      const randomX = noise.simplex2(newNoiseSeedX, 0);
      const randomY = noise.simplex2(newNoiseSeedY, 0);

      const newX = bubble.x - SCROLL_SPEED;

      const newXWithNoise = newX + randomX * NOISE_AMOUNT;
      const newYWithNoise = bubble.y + randomY * NOISE_AMOUNT;

      const element = document.getElementById(`bubble-${index}`);

      if (element) {
        element.style.transform = `translate(${newXWithNoise}px, ${newYWithNoise}px) scale(${bubble.s})`;
      }

      return {
        ...bubble,
        noiseSeedX: newNoiseSeedX,
        noiseSeedY: newNoiseSeedY,
        x: newX < -200 ? CANVAS_WIDTH : newX,
        xWithNoise: newXWithNoise,
        yWithNoise: newYWithNoise,
      };
    });

    animationRef.current = requestAnimationFrame(animate);
  }
  return (
    <div className="bubbles-wrapper">
      <div className="bubbles">
        {bubbles.map((bubble, index) => (
          <div
            className="bubble"
            id={`bubble-${index}`}
            key={`${bubble.x} ${bubble.y}`}
            style={{
              backgroundPosition: backGroundPositions[index],
              transform: `translate(${bubble.x}px, ${bubble.y}px) scale(${bubble.s})`,
              objectFit: "contain",
            }}
          >
            <img
              src={require(`./nfts/${bubble.image}.png`)}
              sloading="lazy"
              style={{
                objectFit: "fill",
                height: "100%",
                width: "100%",
                borderRadius: "50%",
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
export default BackGround;
/**
 * 
 * { image: 1, s: 0.6, x: 1134, y: 45 },
  { image: 2, s: 0.6, x: 1620, y: 271 },
  { image: 3, s: 0.6, x: 1761, y: 372 },
  { image: 4, s: 0.6, x: 2499, y: 79 },
  { image: 5, s: 0.8, x: 2704, y: 334 },
  { image: 6, s: 0.6, x: 2271, y: 356 },
  { image: 7, s: 0.6, x: 795, y: 226 },
  { image: 8, s: 0.6, x: 276, y: 256 },
  { image: 9, s: 0.6, x: 1210, y: 365 },
  { image: 10, s: 0.6, x: 444, y: 193 },
  { image: 11, s: 0.6, x: 2545, y: 387 },

  { image: 12, s: 0.8, x: 1303, y: 193 },
  { image: 13, s: 0.8, x: 907, y: 88 },
  { image: 14, s: 0.8, x: 633, y: 320 },
  { image: 15, s: 0.8, x: 323, y: 60 },
  { image: 16, s: 0.8, x: 129, y: 357 },
  { image: 17, s: 0.8, x: 1440, y: 342 },
  { image: 18, s: 0.8, x: 1929, y: 293 },
  { image: 19, s: 0.8, x: 2135, y: 198 },
  { image: 20, s: 0.8, x: 2276, y: 82 },
  { image: 21, s: 0.8, x: 2654, y: 182 },
  { image: 22, s: 0.8, x: 2783, y: 60 },
  { image: 23, s: 1.0, x: 1519, y: 118 },
  { image: 24, s: 1.0, x: 1071, y: 233 },
  { image: 25, s: 1.0, x: 1773, y: 148 },
  { image: 26, s: 1.0, x: 2098, y: 385 },
  { image: 27, s: 1.0, x: 2423, y: 244 },
  { image: 28, s: 1.0, x: 901, y: 385 },
  { image: 29, s: 1.0, x: 624, y: 111 },
  { image: 30, s: 1.0, x: 75, y: 103 },
  { image: 31, s: 1.0, x: 413, y: 367 },
  { image: 32, s: 1.0, x: 2895, y: 271 },
  { image: 33, s: 1.0, x: 1990, y: 75 },
 */

/**
   * 
   * { image: 1, s: 0.6, x: 1134, y: 45 },
  { image: 2, s: 0.6, x: 1620, y: 571 },
  { image: 3, s: 0.6, x: 1761, y: 672 },
  { image: 4, s: 0.6, x: 2499, y: 109 },
  { image: 5, s: 0.8, x: 2704, y: 334 },
  { image: 6, s: 0.6, x: 2271, y: 656 },
  { image: 7, s: 0.6, x: 795, y: 226 },
  { image: 8, s: 0.6, x: 276, y: 256 },
  { image: 9, s: 0.6, x: 1210, y: 365 },
  { image: 10, s: 0.6, x: 444, y: 193 },
  { image: 11, s: 0.6, x: 2545, y: 387 },

  { image: 12, s: 0.8, x: 1303, y: 193 },
  { image: 13, s: 0.8, x: 907, y: 88 },
  { image: 14, s: 0.8, x: 633, y: 620 },
  { image: 15, s: 0.8, x: 323, y: 60 },
  { image: 16, s: 0.8, x: 129, y: 657 },
  { image: 17, s: 0.8, x: 1440, y: 642 },
  { image: 18, s: 0.8, x: 1929, y: 293 },
  { image: 19, s: 0.8, x: 2135, y: 198 },
  { image: 20, s: 0.8, x: 2276, y: 82 },
  { image: 21, s: 0.8, x: 2654, y: 482 },
  { image: 22, s: 0.8, x: 2783, y: 60 },

  { image: 23, s: 1.0, x: 1519, y: 118 },
  { image: 24, s: 1.0, x: 1071, y: 533 },
  { image: 25, s: 1.0, x: 1773, y: 148 },
  { image: 26, s: 1.0, x: 2098, y: 685 },
  { image: 27, s: 1.0, x: 2423, y: 244 },
  { image: 28, s: 1.0, x: 901, y: 685 },
  { image: 29, s: 1.0, x: 624, y: 111 },
  { image: 30, s: 1.0, x: 75, y: 103 },
  { image: 31, s: 1.0, x: 413, y: 367 },
  { image: 32, s: 1.0, x: 2895, y: 571 },
  { image: 33, s: 1.0, x: 1990, y: 75 },
   */
