import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import ConstructionIcon from "@mui/icons-material/Construction";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import BackGround from "./BackGround";

//Firebase
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyC3DZX6cepyxTZ123d9R9dHhtgl2UzWaTA",
  authDomain: "nft-builder-1ae28.firebaseapp.com",
  projectId: "nft-builder-1ae28",
  storageBucket: "nft-builder-1ae28.appspot.com",
  messagingSenderId: "481888800049",
  appId: "1:481888800049:web:a15f35b03bf0951abe3410",
  measurementId: "G-TH1G84BSGG",
};

export const HeaderComponent = () => {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  return (
    <>
      <BackGround />
      <Grid container spacing={1} style={{ zIndex: 1, position: "relative" }}>
        <Grid
          item
          container
          spacing={2}
          xs={12}
          style={{
            marginLeft: "5%",
            marginRight: "5%",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={12} sm={6}>
            <div>
              <Typography variant="h2">
                <b>NFT Builder</b>
              </Typography>
            </div>
            <div>[by LE DEVS]</div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            style={{
              display: "flex",
              // justifyContent: "center",
            }}
          >
            <Box
              sx={{
                m: 1,
                display: { xs: "none", sm: "none", md: "block" },
              }}
            >
              <Button
                size="large"
                onClick={() => {
                  logEvent(analytics, "clicked_how-to-guide");
                  window.open(
                    "https://ledevs.medium.com/how-to-use-nft-builder-13de03bed7bf"
                  );
                }}
                style={{
                  textTransform: "none",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  color: "black",
                }}
              >
                How To Guide
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          spacing={0}
          style={{
            padding: 25,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
              width: "100vh",
              backgroundColor: "rgba(59,61,66,0.9)",
              borderRadius: 20,
            }}
          >
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                position: "absolute",
              }}
            >
              <Stack
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <h1
                  style={{
                    width: "70%",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <b>No Code NFT Collection Builder</b>
                </h1>

                <h3
                  style={{
                    width: "70%",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "inline",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  NFT Builder makes creating NFT collections a breeze in 7 easy
                  steps. Supports{" "}
                  {
                    <h3
                      style={{
                        display: "inline",
                        color: "orange",
                      }}
                    >
                      CIP-25 Metadata
                    </h3>
                  }{" "}
                  schema export
                </h3>

                <div
                  style={{
                    margin: 15,
                  }}
                >
                  <Box
                    sx={{
                      m: 1,
                      display: { xs: "none", sm: "none", md: "block" },
                    }}
                  >
                    <Button
                      href="/nftTool"
                      onClick={() => {
                        logEvent(analytics, "clicked_go-to-builder");
                      }}
                      variant="contained"
                      startIcon={<ConstructionIcon />}
                      style={{ backgroundColor: "#c2f9bb" }}
                    >
                      Go To Builder
                    </Button>
                  </Box>
                  <Box sx={{ m: 1, display: { xs: "block", md: "none" } }}>
                    <Button
                      disabled
                      href="/nftTool"
                      variant="contained"
                      startIcon={<ConstructionIcon />}
                      style={{ backgroundColor: "black" }}
                    >
                      Go To Builder
                    </Button>
                  </Box>
                </div>
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ m: 1, display: { xs: "block", md: "none" } }}>
              <Button
                onClick={() => {
                  logEvent(analytics, "clicked_how-to-guide-mobile");
                  window.open(
                    "https://ledevs.medium.com/how-to-use-nft-builder-13de03bed7bf"
                  );
                }}
                size="large"
                style={{
                  textTransform: "none",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  color: "black",
                }}
              >
                How To Guide
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            leDevsinfo@gmail.com
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ m: 1, display: { xs: "block", md: "none" } }}>
              <h2
                style={{
                  display: "inline",
                  color: "black",
                  fontWeight: "normal",
                }}
              >
                {
                  <h2
                    style={{
                      fontWeight: "bolder",
                      display: "inline",
                      color: "green",
                    }}
                  >
                    NOTE:{" "}
                  </h2>
                }{" "}
                The builder is not compatible with mobile browsers. Please acces
                the builder on a desktop browser.
              </h2>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default HeaderComponent;
{
  /* <Grid
            item
            xs={3}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <a
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                // color: "white",
              }}
            >
              About
            </a>
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <a
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                // color: "white",
              }}
            >
              How To
            </a>
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <a
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                // color: "white",
              }}
            >
              Contact
            </a>
          </Grid> */
}
