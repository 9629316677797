import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { LandingPage } from "./routes";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <>
        {/* <Helmet>
          <style>{"body { background-color: #f0f0f0; }"}</style>
        </Helmet> */}
        {/* <Helmet>
          <style>{"body { background-color: black; }"}</style>
        </Helmet> */}
        <LandingPage />
      </>
    </ThemeProvider>
  );
}

export default App;
