import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toolActions, con_toolActions } from "../reducer";
import mergeImages from "merge-images";

export const useUtilsDashboardView = () => {
  const nftToolVaribales = useSelector(({ toolReducer }) => toolReducer);

  const dispatch = useDispatch();

  const nftToolMethods = {
    setContainerModal: (x) => {
      // x = {open: bool, type: string}
      dispatch(con_toolActions.setContainerModal(x));
    },
    setLoading: (x) => {
      console.log("Set loading viewer", x);
      dispatch(toolActions.setLoading(x));
    },
    setStep: (x) => {
      dispatch(toolActions.setStep(x));
    },
    setFiles: (x) => {
      dispatch(toolActions.setFiles(x));
    },
    setLayersDict: (x) => {
      dispatch(toolActions.setLayersDict(x));
    },
    loadImageData: (x) => {
      let layersDict = x.layersDict;
      let files = x.files;

      // Initialize Layers
      let layersArray = [];
      let selectedAssets = {};
      for (const [key, value] of Object.entries(layersDict)) {
        // Per Key append the first asset of each key
        layersArray.push(key);
        if (layersDict[key].assets.length > 0) {
          let asset = {
            layer: key,
            asset: layersDict[key].assets[0],
            rarity: 0,
            index: 0,
          };
          selectedAssets[key] = asset;
        }
      }

      //Initialize layersDict with rarity values
      console.log("First time setting rarirty values ");
      for (let layer of layersArray) {
        let numberOfAssets = layersDict[layer].assets.length;
        let quotient = parseFloat(100 / numberOfAssets);
        const updatedAssets = layersDict[layer].assets.map((asset) => {
          let assetEdit = { ...asset };
          assetEdit.rarity = parseFloat(quotient.toFixed(2));

          return assetEdit;
        });

        layersDict[layer].assets = updatedAssets;
      }

      let payload = {
        arrayOfLayers: layersArray,
        dictOfAssets: selectedAssets,
        layersDict: layersDict,
        files: files,
      };
      dispatch(toolActions.loadImageData(payload));
    },

    moveLayerUp: (x) => {
      const layers = [...nftToolVaribales.arrayOfLayers];
      let indexToMove = x;
      if (layers.length > 1) {
        let currentLayer = layers[indexToMove];
        let layerToMoveUp = layers[indexToMove - 1];
        layers[indexToMove - 1] = currentLayer;
        layers[indexToMove] = layerToMoveUp;
      }
      nftToolMethods.reGenImageLayerUpdate(layers);
      dispatch(toolActions.setArrayOfLayers(layers));
    },
    moveLayerDown: (x) => {
      const layers = [...nftToolVaribales.arrayOfLayers];
      let indexToMove = x;
      if (layers.length > 1) {
        let currentLayer = layers[indexToMove];
        let layerToMoveDown = layers[indexToMove + 1];
        layers[indexToMove + 1] = currentLayer;
        layers[indexToMove] = layerToMoveDown;
      }
      nftToolMethods.reGenImageLayerUpdate(layers);
      dispatch(toolActions.setArrayOfLayers(layers));
    },
    reGenImageLayerUpdate: async (x) => {
      //Get current selected item per layer
      const layerOrder = x;
      const selectedAssets = nftToolVaribales.dictOfAssets;

      const uris = layerOrder.map((layer) => {
        const item = selectedAssets[layer];

        return URL.createObjectURL(item.asset.file);
      });
      // MERGE THE IMAGES AND CREATE A NEW ONE
      const b64 = await mergeImages(uris);
      dispatch(toolActions.setTempPicture(b64));
    },
    generateLayerCategories: (x) => {
      let layers = {};

      for (let item of x) {
        let path = item.path;
        let pathTokens = path.split("/");

        let root = pathTokens[1];
        let layer = pathTokens[2];
        let asset = {
          name: pathTokens[3],
          preview: item.preview,
          file: item,
          selected: false,
          rarity: 0,
        };

        if (layer in layers) {
          let assets = layers[layer].assets;
          assets.push(asset);
        } else {
          layers[layer] = { assets: [asset], rarity: 100 };
        }
      }

      return layers;
    },
    generateInitialPreviewImage: async (x) => {
      const selectedAssets = nftToolVaribales.dictOfAssets;
      const layerOrder = nftToolVaribales.arrayOfLayers;
      // CREATE INITIAL IMAGE
      /*
      The initial image will take the current
       order of the layers
       and the first asset of each layer 
      */
      const uris = layerOrder.map((layer) => {
        const item = selectedAssets[layer];
        return URL.createObjectURL(item.asset.file);
      });
      // MERGE THE IMAGES AND CREATE A NEW ONE
      const b64 = await mergeImages(uris);
      dispatch(toolActions.setTempPicture(b64));
    },
    selectImage: async (x) => {
      let layer = x.file.path.split("/")[2];
      let name = x.file.path.split("/")[3];
      console.log("Layer and name ", layer, name, x);
      // Update dictionary of assets with new selected asset per layer
      let currentSelectedAssets = { ...nftToolVaribales.dictOfAssets };
      currentSelectedAssets[layer] = {
        layer: layer,
        asset: { ...x },
      };

      // CREATE IMAGE
      const layerOrder = nftToolVaribales.arrayOfLayers;

      const uris = layerOrder.map((layer) => {
        const item = currentSelectedAssets[layer];
        return URL.createObjectURL(item.asset.file);
      });
      // MERGE THE IMAGES AND CREATE A NEW ONE
      const b64 = await mergeImages(uris);
      dispatch(toolActions.setTempPicture(b64));

      dispatch(toolActions.setDictOfAssets(currentSelectedAssets));
    },
    selectLayer: (x) => {
      dispatch(toolActions.setSelectedLayer(x));
    },
    updateLayerRarity: (x) => {
      let layer = x.layer;
      let percent = x.newValue;
      let layerData = { ...x.layerData };
      layerData.rarity = percent;

      const currentLayersDict = { ...nftToolVaribales.layersDict };
      currentLayersDict[layer] = layerData;
      console.log("UPDATED LAYER DATA -> ", currentLayersDict);
      dispatch(toolActions.setLayersDict(currentLayersDict));
    },

    setWarningMessage: (x) => {
      dispatch(toolActions.setWarningMessage(x));
    },
    rebalanceAssetRarityPerLayer: (x) => {
      /**
       * - Get asset being updated
       * - upate asset
       * - insert updated asset
       * - calculate that layer percentages add up to 100
       */

      let layersDictCopy = { ...nftToolVaribales.layersDict };
      console.log("Rebalance Asset Rarity", layersDictCopy);
      let editedAssetCopy = JSON.parse(JSON.stringify(x.asset));
      editedAssetCopy.rarity = x.rarity === "" ? 0 : parseFloat(x.rarity);

      // Deep copy layersDict
      let assets = layersDictCopy[x.layer].assets.map((a) => {
        let aCopy = { ...a };
        return aCopy;
      });

      let count = 0;
      // Find asset being edited and update its rarity
      // Update rarity point count for layer
      for (let asset of assets) {
        if (asset.name === editedAssetCopy.name) {
          asset.rarity = editedAssetCopy.rarity;
          count += parseFloat(asset.rarity);
        } else {
          count += parseFloat(asset.rarity);
        }
      }
      /// Update Layer
      let p = {
        layer: x.layer,
        assets: assets,
      };
      dispatch(toolActions.updateLayersDict(p));

      // Calculate layer percentages
      count = parseFloat(count).toFixed(2);
      console.log("Count *** ", count);
      if (Math.round(count) === 100) {
        console.log("Count is 100");
        dispatch(toolActions.setLayerPercentStatus(""));
        dispatch(toolActions.setAllowNext(false));
      } else {
        let dif = Math.round(count) - 100;
        let errorMessage =
          dif > 0
            ? `Rarity values add up to ${count}, please remove ${dif} rarity points to make 100`
            : `Rarity values add up to ${count}, please distribute ${
                dif * -1
              } rarity points to make 100`;
        dispatch(toolActions.setLayerPercentStatus(errorMessage));
        dispatch(toolActions.setAllowNext(true));
      }
    },
    checkAllLayers: (x) => {
      let layers = nftToolVaribales.arrayOfLayers;
      let layersDictCopy = nftToolVaribales.layersDict;

      let pass = true;
      let errorLayers = [];

      for (let layer of layers) {
        let count = 0;
        let assets = layersDictCopy[layer].assets;
        for (let asset of assets) {
          count += parseFloat(asset.rarity);
        }
        count = parseFloat(count).toFixed(2);
        if (Math.round(count) !== 100) {
          pass = false;
          errorLayers.push(layer);
        }
      }

      let p = { message: "", layers: [] };
      if (!pass) {
        p.message = "The following layers do not add up to 100%";
        p.layers = errorLayers;

        dispatch(toolActions.setWarningMessage(p));
      } else {
        dispatch(toolActions.setWarningMessage(p));
        dispatch(toolActions.setStep(5));
      }
    },
    setName: (x) => {
      dispatch(toolActions.setName(x));
    },
    setDescription: (x) => {
      dispatch(toolActions.setDescription(x));
    },
    setNumber: (x) => {
      dispatch(toolActions.setNumber(x));
    },
    setCollectionData: (x) => {
      dispatch(toolActions.setCollectionData(x));
    },
    createCheckOutSession: async () => {
      const checkOut = await axios.post(
        "http://localhost:8000/create-checkout-session",
        {
          item: [{ id: 1, quantity: 1 }],
        }
      );
      // IF THIS IS A SUCCESS REDIRECT TO THE PROVIDED URL
      console.log("Check out response ", checkOut.data);
      var win = window.open(checkOut.data.url, "_blank");
      win.focus();
      // window.location = checkOut.data.url;
    },
  };

  return {
    nftToolVaribales,
    nftToolMethods,
  };
};
