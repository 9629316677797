import React from "react";
import Typography from "@mui/material/Typography";

export const Header = ({ children, style }) => (
  <Typography variant="h3" component="div" style={{ ...style }}>
    {children}
  </Typography>
);

export default Header;
