import { useState } from "react";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";

import { BodyText, EditButton } from "../../../../common";
import { useUtilsDashboardView } from "../../Hooks/useUtils";

export const BlockListCard = (p) => {
  const { nftToolMethods, nftToolVaribales } = useUtilsDashboardView();
  const values = nftToolVaribales;
  const methods = nftToolMethods;

  const i = p.i;
  const assetName = i.name;
  const assetBlockList = values.blockListObject[assetName];

  return (
    <div
      style={{
        margin: 10,
        padding: 4,
      }}
      key={assetName}
    >
      <div
        style={{
          padding: 5,
          borderTopRightRadius: 5,
          borderTopLeftRadius: 5,
          border: "1px dashed black",
        }}
      >
        <img
          alt=""
          src={`${i.preview}`}
          loading="lazy"
          onClick={() => {
            methods.selectImage(i);
          }}
          style={{
            objectFit: "contain",
            width: "100%",
            height: "100%",
          }}
        />
      </div>

      <Box
        sx={{
          "& .MuiTextField-root": { m: 1 },
        }}
        noValidate
        autoComplete="off"
      >
        <div
          style={{
            backgroundColor: "white",
            padding: 5,
            borderBottomRightRadius: 10,
            borderBottomLeftRadius: 10,
          }}
        >
          <Grid container style={{ paddingBottom: 10 }}>
            <Grid item xs={6}>
              <BodyText>Block List</BodyText>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                justifyContent: "flex-end",
                display: "flex",
              }}
            >
              <EditButton
                variant="outlined"
                onClick={() => {
                  console.log("Asset block list", assetBlockList);
                  p.setSelectedAsset(p.i, p.layer);
                  p.setSelectedItems(assetBlockList);
                  p.openModal(true);
                }}
              >
                Edit Block List
              </EditButton>
            </Grid>
          </Grid>
          {assetBlockList?.length > 0 ? (
            <>
              {assetBlockList.map((item, num) => (
                <Chip label={item} key={item.name} style={{ margin: 3 }} />
              ))}
            </>
          ) : null}
        </div>
      </Box>
    </div>
  );
};

export default BlockListCard;
