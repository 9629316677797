import Grid from "@mui/material/Grid";

import { Header, PaperNormal, PaperDark, TextH6 } from "../../../common";
import { useUtilsDashboardView } from "../Hooks/useUtils";

import RarityCard from "./rarityCard";

export const ViewLayerRarity = () => {
  const { nftToolVaribales } = useUtilsDashboardView();
  const values = nftToolVaribales;

  let layer = values.arrayOfLayers[values.selectedLayer];
  let layerData = values.layersDict[layer];

  return (
    <Grid item xs={12}>
      <Header>{`${layer}`}</Header>
      <PaperDark
        style={{
          padding: 5,
          marginTop: 5,
        }}
      >
        {nftToolVaribales.layerPercentStatus !== "" ? (
          <>
            <PaperNormal
              style={{ backgroundColor: "#001722", textAlign: "center" }}
            >
              <TextH6
                style={{
                  color: "orange",
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                LAYER WARNING:
              </TextH6>
              <TextH6 style={{ color: "#f8dfb0", textAlign: "left" }}>
                {nftToolVaribales.layerPercentStatus}
              </TextH6>
            </PaperNormal>
          </>
        ) : null}
        {/* RARITY CARDS */}
        <Grid item container xs={12}>
          {layerData.assets.map((i, num) => (
            <Grid item xs={2} key={num}>
              <RarityCard
                i={i}
                num={num}
                max={layerData.maxPercent}
                min={layerData.minPercent}
              />
            </Grid>
          ))}
        </Grid>
      </PaperDark>
    </Grid>
  );
};

export default ViewLayerRarity;
