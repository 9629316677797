import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
// Hooks
import { useUtilNft } from "../../Hooks/generateNfts";
import { PreviewNftAndMetaData } from "../previewNftAndMetaData";
import { useUtilsStep6 } from "../../Hooks/step6Utils";
// Common
import { GuardSpinner } from "react-spinners-kit";
import {
  BodyText,
  Header,
  PaperNormal,
  PaperDark,
  OptionalTF,
  RequiredTF,
} from "../../../../common";

export const Step5 = () => {
  const { preview } = useUtilNft();
  const { nftToolMethods, r_name, r_description, r_number, r_loading } =
    useUtilsStep6();

  const methods = nftToolMethods;

  const [name, setName] = useState(r_name);
  const [description, setDescription] = useState(r_description);
  const [number, setNumber] = useState(r_number);

  const [maxNumberOfNFTS, setMaxNumberOfNFTS] = useState(null);

  useEffect(() => {
    console.log("Step 6 ");

    let maxNumber = methods.calcMax_2();
    setMaxNumberOfNFTS(maxNumber);
  }, [maxNumberOfNFTS, methods]);

  return (
    <>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <Header>
          <b>Step 6</b>
        </Header>
      </Grid>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <PaperNormal style={{ maxWidth: "50vw" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <BodyText style={{ fontWeight: "bold" }}>
                Fill in meta data
              </BodyText>
              <BodyText>
                The number of NFT's will determine the size of the collection.
                All NFT's are randomly generated.
              </BodyText>
              <BodyText style={{ textAlign: "left" }}>Note:</BodyText>
              <BodyText style={{ fontSize: 15 }}>
                You can generate unlimited previews. Make sure to generate
                multiple previews to ensure that everything looks correct.
              </BodyText>
            </Grid>
          </Grid>
        </PaperNormal>
      </Grid>
      <Grid
        container
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          margin: 15,
        }}
      >
        <Grid item xs={6}>
          <PaperDark style={{ maxWidth: "50vw" }}>
            <Grid container spacing={2}>
              <Grid item container spacing={2} xs={12}>
                <Grid item xs={12}>
                  <BodyText style={{ color: "white" }}>
                    <b>Collection Name</b>
                  </BodyText>
                </Grid>
                <Grid item xs={12}>
                  <RequiredTF
                    sx={{
                      label: {
                        color: "white",
                      },
                      input: {
                        color: "white",
                      },
                    }}
                    label="Required"
                    fullWidth
                    required
                    variant="outlined"
                    defaultValue={name}
                    id="validation-outlined-input"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item container spacing={2} xs={12}>
                  <Grid item xs={12}>
                    <BodyText style={{ color: "white" }}>
                      <b>Description</b>
                    </BodyText>
                  </Grid>
                  <Grid item xs={12}>
                    <OptionalTF
                      sx={{
                        label: {
                          color: "white",
                        },
                        input: {
                          color: "white",
                        },
                      }}
                      label="Optional"
                      fullWidth
                      id="custom-css-outlined-input"
                      defaultValue={description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item container spacing={2} xs={12}>
                  <Grid item xs={12}>
                    <BodyText style={{ color: "white" }}>
                      <b>Number of NFTS</b>
                    </BodyText>
                    <BodyText style={{ color: "orange", display: "inline" }}>
                      Max number of NFT's possible:{" "}
                    </BodyText>
                    <BodyText
                      style={{
                        color: "orange",
                        display: "inline",
                        fontWeight: "bold",
                      }}
                    >
                      {maxNumberOfNFTS ? maxNumberOfNFTS.toLocaleString() : ""}
                    </BodyText>
                  </Grid>
                  <Grid item xs={12}>
                    <RequiredTF
                      sx={{
                        label: {
                          color: "white",
                        },
                        input: {
                          color: "white",
                        },
                      }}
                      type="number"
                      label="Required"
                      fullWidth
                      required
                      variant="outlined"
                      defaultValue={number}
                      id="validation-outlined-input"
                      onChange={(e) => {
                        setNumber(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </PaperDark>
        </Grid>
      </Grid>
      {/* PREVIEW SECTION */}
      {r_loading ? (
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 15,
          }}
        >
          <PaperDark>
            <GuardSpinner size={30} color="#686769" loading={true} />
          </PaperDark>
        </Grid>
      ) : (
        <PreviewNftAndMetaData loading={r_loading} />
      )}

      <Grid
        item
        xs={4}
        style={{ display: "flex", justifyContent: "flex-end", padding: 15 }}
      >
        <Button
          variant="outlined"
          style={{ color: "red" }}
          onClick={() => methods.setStep(5)}
        >
          Back
        </Button>
      </Grid>
      <Grid
        item
        xs={4}
        style={{ display: "flex", justifyContent: "center", padding: 15 }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            if (name.trim() === "" || number.trim() === "") {
              alert("Please input a name and number for the collection size.");
            } else {
              let payload = {
                name: name,
                description: description,
                number: number,
                preView: "step6",
              };
              preview(payload);
            }
          }}
        >
          Generate Preview
        </Button>
      </Grid>
      <Grid
        item
        xs={4}
        style={{ display: "flex", justifyContent: "flex-start", padding: 15 }}
      >
        <Button
          variant="outlined"
          disabled={maxNumberOfNFTS * 1 < number ? true : false}
          onClick={() => {
            let payload = {
              name: name,
              description: description,
              number: number,
            };
            methods.setCollectionData(payload);
            // methods.setStep(7);
          }}
        >
          Next
        </Button>
      </Grid>
    </>
  );
};

export default Step5;
