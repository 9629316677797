import { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

import ViewLayerRarity from "./viewLayerRarity";
import {
  BodyText,
  TextH6,
  Header,
  PaperNormal,
  PaperDark,
} from "../../../common";
import { useUtilsDashboardView } from "../Hooks/useUtils";
export const Step4 = () => {
  const { nftToolMethods, nftToolVaribales } = useUtilsDashboardView();
  const values = nftToolVaribales;
  const methods = nftToolMethods;

  useEffect(() => {
    console.log("Step 4");
  });
  const RenderLayerCards = () => {
    let layerCards;
    if (values.arrayOfLayers.length > 0) {
      layerCards = values.arrayOfLayers.map((item, index) => {
        let layer = values.layersDict[item];
        return (
          <Grid item xs={12}>
            <PaperDark
              style={{
                border:
                  values.selectedLayer === index
                    ? "2px solid red"
                    : "2px solid green",
              }}
              onClick={() => methods.selectLayer(index)}
            >
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <BodyText
                    style={{
                      color: "white",
                      padding: 5,
                    }}
                  >
                    <b>{`Layer ${index}: ${item}`}</b>
                  </BodyText>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" spacing={1}>
                    {/* <Chip
                      label={`Layer Rarity: ${layer.rarity}%`}
                      color="success"
                    /> */}
                    <Chip
                      label={`Assets In Layer: ${layer.assets.length}`}
                      color="primary"
                    />
                  </Stack>
                </Grid>
              </Grid>
            </PaperDark>
          </Grid>
        );
      });
    }

    return layerCards;
  };

  return (
    <>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <Header>
          <b>Step 4</b>
        </Header>
      </Grid>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <PaperNormal style={{ maxWidth: "50vw" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <BodyText>
                Adjust the rarity of each layer and its associated assets. We do
                some rounding to simplify your life 😉
              </BodyText>
            </Grid>
          </Grid>
        </PaperNormal>
      </Grid>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        {nftToolVaribales.warningMessage !== "" ? (
          <>
            <PaperNormal
              style={{
                backgroundColor: "#001722",
                textAlign: "center",
                maxWidth: "50vw",
              }}
            >
              <>
                <TextH6
                  style={{
                    fontWeight: "bold",
                    color: "orange",
                    textAlign: "left",
                  }}
                >
                  ERROR:
                </TextH6>
                <TextH6 style={{ color: "#f8dfb0", textAlign: "left" }}>
                  {nftToolVaribales.warningMessage}
                </TextH6>
                {nftToolVaribales.errorLayers?.length > 0 ? (
                  <>
                    {nftToolVaribales.errorLayers.map((l) => {
                      return (
                        <Chip
                          label={l}
                          variant="outlined"
                          style={{ color: "white", margin: 5 }}
                        />
                      );
                    })}
                  </>
                ) : null}
                <TextH6 style={{ color: "#f8dfb0" }}>
                  Please double check the rarity values. When finished you
                  should be able to click Next.{" "}
                </TextH6>
              </>
            </PaperNormal>
          </>
        ) : null}
      </Grid>
      <Grid
        container
        xs={12}
        spacing={2}
        direction="row"
        style={{
          margin: 20,
        }}
      >
        {/* SIDE PANNEL */}
        <Grid
          container
          item
          spacing={2}
          xs={3}
          style={{
            maxHeight: "80vh",
            overflowY: "scroll",
            border: "1px solid green",
            padding: 10,
          }}
        >
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <BodyText>
              <b>Layers</b>
            </BodyText>
          </Grid>

          <RenderLayerCards />
        </Grid>
        {/* MIDDLE PANNEL */}
        <Grid container item spacing={2} xs={9}>
          <ViewLayerRarity />
        </Grid>
      </Grid>
      {/* --------FOOTER---- */}
      <Grid
        item
        xs={6}
        style={{ display: "flex", justifyContent: "flex-end", padding: 15 }}
      >
        <Button
          variant="outlined"
          style={{ color: "red" }}
          onClick={() => methods.setStep(3)}
        >
          Back
        </Button>
      </Grid>
      <Grid
        item
        xs={6}
        style={{ display: "flex", justifyContent: "flex-start", padding: 15 }}
      >
        <Button
          variant="outlined"
          disabled={nftToolVaribales.allowNext}
          onClick={() => {
            methods.checkAllLayers();
          }}
        >
          Next
        </Button>
      </Grid>
    </>
  );
};

export default Step4;
