import { Outlet, Link } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import { LandingPageContainer } from "../../modules";
export const LandingPage = () => {
  return (
    <Box sx={{ flexGrow: 1, paddingTop: 5 }}>
      <LandingPageContainer />
      <Outlet />
    </Box>
  );
};
export default LandingPage;
