import { useState } from "react";

import Box from "@mui/material/Box";

import { BodyText, Header, PaperNormal, PaperDark } from "../../../common";
import { useUtilsDashboardView } from "../Hooks/useUtils";

import TextField from "@mui/material/TextField";

export const RarityCard = (p) => {
  const { nftToolMethods, nftToolVaribales } = useUtilsDashboardView();
  const values = nftToolVaribales;
  const methods = nftToolMethods;

  let layer = values.arrayOfLayers[values.selectedLayer];
  const i = p.i;
  const [value, setValue] = useState(i.rarity);

  return (
    <div
      style={{
        borderRadius: 2,
        border: "1px dashed black",
        margin: 8,
        padding: 4,
      }}
    >
      <div style={{ paddingBottom: 5 }}>
        <img
          src={`${i.preview}`}
          loading="lazy"
          onClick={() => {
            methods.selectImage(i);
          }}
          style={{
            objectFit: "contain",
            width: "100%",
            height: "100%",
          }}
        />
      </div>

      <Box
        sx={{
          "& .MuiTextField-root": { m: 1 },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          label="Rarity %"
          defaultValue={i.rarity}
          type="number"
          onBlur={() => {
            let payload = {
              rarity: value,
              asset: i,
              layer: layer,
            };
            nftToolMethods.rebalanceAssetRarityPerLayer(payload);
          }}
          sx={{
            label: {
              color: "white",
            },
            input: {
              color: "white",
            },
          }}
          inputProps={{
            step: 0.01,
            onKeyDown: (e) => {
              if (e.key === "Enter") {
                let payload = {
                  rarity: value,
                  asset: i,
                  layer: layer,
                };
                nftToolMethods.rebalanceAssetRarityPerLayer(payload);
                e.stopPropagation();
              }
            },
          }}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
      </Box>
    </div>
  );
};

export default RarityCard;
