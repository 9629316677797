import { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

import { BodyText, Header, PaperNormal, PaperDark } from "../../../common";
import { useUtilsDashboardView } from "../Hooks/useUtils";

import PreviewNft from "./previewNft";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export const Step3 = () => {
  const { nftToolMethods, nftToolVaribales } = useUtilsDashboardView();
  const [initial, setInitial] = useState(false);
  const values = nftToolVaribales;
  const methods = nftToolMethods;

  const length = Object.keys(values.layersDict).length;

  const renderUpArrow = (s, index) => {
    if (index === 0) {
      return (
        <Button
          size="small"
          disabled
          onClick={() => {}}
          style={{ textTransform: "none" }}
        >
          <KeyboardArrowUpIcon />
        </Button>
      );
    } else {
      return (
        <Button
          size="small"
          variant="contained"
          onClick={() => {
            methods.moveLayerUp(index);
          }}
          style={{ textTransform: "none" }}
        >
          <KeyboardArrowUpIcon />
        </Button>
      );
    }
  };
  const renderDownArrow = (s, index) => {
    //Check if it is the last stop in the array
    if (index === length - 1) {
      return (
        <Button
          disabled
          size="small"
          onClick={() => {}}
          style={{ textTransform: "none" }}
        >
          <KeyboardArrowDownIcon />
        </Button>
      );
    } else {
      return (
        <Button
          size="small"
          variant="contained"
          onClick={() => {
            methods.moveLayerDown(index);
          }}
          style={{ textTransform: "none" }}
        >
          <KeyboardArrowDownIcon />
        </Button>
      );
    }
  };

  const renderSelectedImage = (i) => {
    if (i.selected) {
      return (
        <img
          alt={`${i.name}`}
          src={`${i.preview}`}
          srcSet={`${i.preview}`}
          loading="lazy"
          style={{
            objectFit: "contain",
            border: "2px solid red",
          }}
        />
      );
    } else {
      return (
        <img
          alt={`${i.name}`}
          src={`${i.preview}`}
          srcSet={`${i.preview}`}
          loading="lazy"
          onClick={() => {
            methods.selectImage(i);
          }}
          style={{
            objectFit: "contain",
            border: "1px dashed black",
          }}
        />
      );
    }
  };

  const RenderLayerCards = () => {
    let layerCards;
    if (
      Object.values(values.layersDict).length > 0 &&
      values.arrayOfLayers.length > 0
    ) {
      let layerObjects = [];
      for (let layer of values.arrayOfLayers) {
        const assets = values.layersDict[layer].assets;

        // Check selected assets dictionary to mark selected boolean and include it in the object
        const updateSelected = assets.map((a) => {
          let currentSelected = values.dictOfAssets[layer];
          if (currentSelected.asset.name === a.name) {
            return { ...a, selected: true };
          } else {
            return { ...a };
          }
        });

        let object = { name: layer, assets: updateSelected };
        layerObjects.push(object);
      }

      layerCards = layerObjects.map((item, index) => (
        <Grid
          continer
          item
          xs={12}
          spacing={2}
          key={index}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid
            item
            xs={1}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <div>
              <div style={{ paddingBottom: 10 }}>
                {renderUpArrow(item, index)}
              </div>
              <div>{renderDownArrow(item, index)}</div>
            </div>
          </Grid>

          <Grid item xs={11}>
            <PaperDark>
              <BodyText
                style={{
                  color: "white",
                  padding: 5,
                }}
              >
                <b>
                  <b>{`Layer ${index}: ${item.name}`}</b>
                </b>
              </BodyText>

              <ImageList gap={10} cols={4} style={{ padding: 2 }}>
                {item.assets.map((i, num) => (
                  <ImageListItem key={num}>
                    {renderSelectedImage(i)}
                  </ImageListItem>
                ))}
              </ImageList>
            </PaperDark>
          </Grid>
        </Grid>
      ));

      return layerCards;
    }
  };

  useEffect(() => {
    if (values.tempPicture === null) {
      console.log("Initial values at step 3 ", values);
      window.scrollTo(0, 0);
      methods.generateInitialPreviewImage();
    }
  }, [values, methods]);

  return (
    <>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <Header>
          <b>Step 3</b>
        </Header>
      </Grid>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <PaperNormal style={{ maxWidth: "50vw" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <BodyText>
                Set the order of the layers and play around with the asset
                combinations to make sure that everything lines up properly.
              </BodyText>
            </Grid>
          </Grid>
        </PaperNormal>
      </Grid>
      <Grid
        container
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          maxHeight: "75vh",

          border: "2px solid green",
          marginTop: 15,
        }}
      >
        <Grid
          container
          item
          xs={6}
          spacing={2}
          style={{
            maxHeight: "100%",
            overflowY: "scroll",
          }}
        >
          <RenderLayerCards />
        </Grid>
        <Grid
          container
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: "center",
            padding: 10,
            maxWidth: "50%",
            maxHeight: "100%",
          }}
        >
          <PreviewNft />
        </Grid>
      </Grid>
      <Grid
        item
        xs={6}
        style={{ display: "flex", justifyContent: "flex-end", padding: 15 }}
      >
        <Button
          variant="outlined"
          style={{ color: "red" }}
          onClick={() => methods.setStep(2)}
        >
          Back
        </Button>
      </Grid>
      <Grid
        item
        xs={6}
        style={{ display: "flex", justifyContent: "flex-start", padding: 15 }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            // methods.initializeData();
            methods.setStep(4);
          }}
        >
          Next
        </Button>
      </Grid>
    </>
  );
};

export default Step3;
