import { useDispatch, useSelector } from "react-redux";
import { toolActions } from "../reducer/toolReducer";

export const useUtilsStep5 = () => {
  const nftToolVaribales = useSelector(({ toolReducer }) => toolReducer);

  const dispatch = useDispatch();

  const step5Methods = {
    setLoading: (x) => {
      console.log("Set loading viewer", x);
      dispatch(toolActions.setLoading(x));
    },
    setStep: (x) => {
      dispatch(toolActions.setStep(x));
    },
    setFiles: (x) => {
      dispatch(toolActions.setFiles(x));
    },
    setLayersDict: (x) => {
      dispatch(toolActions.setLayersDict(x));
    },
    generateBlockListData: (x) => {
      /*
        1. Create block list object 
        2. create list of all assets 
      */
      let tempBlockListObJ = {};
      nftToolVaribales.arrayOfLayers.forEach((layer) => {
        nftToolVaribales.layersDict[layer].assets.forEach(
          (asset) => (tempBlockListObJ[asset.name] = [])
        );
      });
      console.log("Block List created", tempBlockListObJ);
      dispatch(toolActions.setBlockListObject(tempBlockListObJ));
    },
    addToBlockList: (assetName, items) => {
      console.log("Asset name ", assetName);
      console.log("Items ", items);
      let payload = {
        assetName: assetName,
        items: items,
      };

      dispatch(toolActions.addToBlockList(payload));
    },
  };

  return {
    nftToolVaribales,
    step5Methods,
  };
};
