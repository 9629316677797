import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  loading: false,
  step: 1,
  files: [],
  // This dictionary is the original copy of all the files uploaded
  layersDict: null,
  // Step 3
  tempPicture: null,
  arrayOfLayers: [],
  // This dictionary is used when constructing the temporary nft preview
  dictOfAssets: {},
  //Step 4
  selectedLayer: 0,
  warningMessage: "",
  layerPercentStatus: "",
  allowNext: false,
  errorLayers: [],
  // Step 5
  blockListObject: {},

  // step 6
  previewImages: [],
  metaData: [],
  name: "",
  description: "",
  number: "",

  // step 7
  generationModal: false,
  progress: 0,
  engineState: "",
};

const ToolReducerSlice = createSlice({
  name: "ToolReducerSlice",
  initialState: INITIAL_STATE,
  reducers: {
    loadImageData: (state, action) => {
      let p = action.payload;
      state.arrayOfLayers = p.arrayOfLayers;
      state.dictOfAssets = p.dictOfAssets;
      state.layersDict = p.layersDict;
      state.files = p.files;
      state.loading = false;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setFiles: (state, action) => {
      state.files = action.payload;
      state.loading = false;
    },
    setLayersDict: (state, action) => {
      state.layersDict = action.payload;
    },
    updateLayersDict: (state, action) => {
      let layer = action.payload.layer;
      let assets = action.payload.assets;
      state.layersDict[layer].assets = assets;
    },
    setTempPicture: (state, action) => {
      state.tempPicture = action.payload;
      state.loading = false;
    },
    setArrayOfLayers: (state, action) => {
      state.arrayOfLayers = action.payload;
    },
    setDictOfAssets: (state, action) => {
      state.dictOfAssets = action.payload;
    },
    setSelectedLayer: (state, action) => {
      state.selectedLayer = action.payload;
    },
    setWarningMessage: (state, action) => {
      state.errorLayers = action.payload.layers;
      state.warningMessage = action.payload.message;
    },
    setLayerPercentStatus: (state, action) => {
      state.layerPercentStatus = action.payload;
    },
    setAllowNext: (state, action) => {
      state.allowNext = action.payload;
    },
    setBlockListObject: (state, action) => {
      state.blockListObject = action.payload;
    },
    addToBlockList: (state, action) => {
      let assetName = action.payload.assetName;
      let items = action.payload.items;

      state.blockListObject[assetName] = items;
    },
    setPreviewImages: (state, action) => {
      state.previewImages = action.payload;
    },
    setMetaData: (state, action) => {
      state.metaData = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setNumber: (state, action) => {
      state.number = action.payload;
    },
    setCollectionData: (state, action) => {
      state.name = action.payload.name;
      state.description = action.payload.description;
      state.number = action.payload.number;
      state.step = 7;
      if (state.previewImages.length < 1) {
        state.loading = true;
      }
    },
    startPreviewGen: (state, action) => {
      state.loading = true;
      state.name = action.payload.name;
      state.description = action.payload.description;
      state.number = action.payload.number;
    },
    displayPreview: (state, action) => {
      state.previewImages = action.payload.previewImages;
      state.metaData = action.payload.metaData;
      state.loading = false;
    },
    startMainNftGeneration: (state, action) => {
      state.generationModal = action.payload;
      state.engineState = "Spinning up NFT randomization engine ...";
    },
    setEngineState: (state, action) => {
      state.engineState = action.payload;
    },
    updateProgress: (state, action) => {
      state.progress = action.payload;
    },
  },
  extraReducers: {},
});

export const toolActions = ToolReducerSlice.actions;
export const toolReducer = ToolReducerSlice.reducer;
