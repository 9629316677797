import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import {
  BodyText,
  Header,
  PaperNormal,
  PaperDark,
  TextH6,
  SubText,
  LargeHeader,
} from "../../../../common";
export const RadioButtonGroup = (p) => {
  return (
    <FormControl>
      <TextH6 style={{ fontWeight: "bold" }}>Order Type</TextH6>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={(e) => {
          console.log("Radio ", e.target.value);
          p.p(e.target.value);
        }}
      >
        <FormControlLabel value="Paid" control={<Radio />} label="Paid" />
        <FormControlLabel value="Free" control={<Radio />} label="Free" />
      </RadioGroup>
    </FormControl>
  );
};
