import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { PayButton } from "../../../../common";

import { useUtilNft } from "../../Hooks/generateNfts";
import { useUtilsStep7 } from "../../Hooks/step7Utils";

import { FlapperSpinner } from "react-spinners-kit";
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const CardForm = (props) => {
  const { nftToolMethods, nftToolVaribales } = useUtilsStep7();
  let values = nftToolVaribales;
  const { main } = useUtilNft();
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const methods = nftToolMethods;

  const handleSubmit = async (e) => {
    const name = props.customerName;
    const email = props.email;
    const phone = props.phone;
    setLoading(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    e.preventDefault();

    // STRIPE NOT LOADED
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      console.log("Stripe not yet loaded");
      setLoading(false);
      return;
    }
    //"https://backend-nft-builder.onrender.com/create-payment-intent"
    // "http://localhost:3000/create-payment-intent",
    const { backendError, clientSecret } = await fetch(
      "https://backend-nft-builder.onrender.com/create-payment-intent",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          paymentMethodType: "card",
          currency: "usd",
          precio: Math.ceil(values.number / 100) * 1.69 * 100,
        }),
      }
    ).then((r) => r.json());

    // BACK-END ERROR
    if (backendError) {
      console.log("Back-end error", backendError.message);
      methods.setSnackBar({
        open: true,
        message:
          "Oops! There was an error in our back-end. Please contact support.",
      });
      setLoading(false);
      return;
    }

    const { error: stripeError, paymentIntent } =
      await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: name,
            email: email,
            phone: phone,
          },
        },
      });

    // PAYMENT ERROR
    if (stripeError) {
      methods.setSnackBar({
        open: true,
        message: `Payment Error: ${stripeError.message}`,
      });
      setLoading(false);
      return;
    }

    // Show a success message to your customer
    // There's a risk of the customer closing the window before callback
    // execution. Set up a webhook or plugin to listen for the
    // payment_intent.succeeded event that handles any business critical
    // post-payment actions.

    methods.setSnackBar({
      open: true,
      message: `Payment Succeeded! Nft generation will begin.`,
    });
    const p = { type: "paid" };
    main(p);

    setLoading(false);
  };

  return (
    <div>
      <h1>Card</h1>

      <form id="payment-form">
        <label htmlFor="card">Card</label>
        <CardElement id="card" options={CARD_ELEMENT_OPTIONS} />

        {loading ? (
          <div
            style={{ display: "flex", justifyContent: "center", padding: 10 }}
          >
            <FlapperSpinner color="#686769" loading={true} />
          </div>
        ) : (
          <>
            <PayButton
              onClick={(e) => {
                if (props.checkTextFields()) {
                  handleSubmit(e);
                }
              }}
            >
              Pay & Generate
            </PayButton>
          </>
        )}
      </form>
    </div>
  );
};

export default CardForm;
