import { useEffect, useState } from "react";

// MUI
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
// Select
import ListSubheader from "@mui/material/ListSubheader";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import Chip from "@mui/material/Chip";

import {
  BodyText,
  Header,
  PaperNormal,
  PaperDark,
  TextH6,
} from "../../../../common";

import { useUtilsStep5 } from "../../Hooks/step5Utils";

import BlockListCard from "./blockListCard";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, selectedItems, theme) {
  return {
    fontWeight:
      selectedItems.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const Step5 = () => {
  const { nftToolVaribales, step5Methods } = useUtilsStep5();

  const values = nftToolVaribales;
  const methods = step5Methods;

  const [openModal, setOpenModal] = useState(false);
  const [slAsstData, setSlAsstData] = useState({
    selectedAsset: null,
    layersArray: values.arrayOfLayers,
  });

  // SELECT
  const theme = useTheme();
  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelectAsset = (asset, layer) => {
    let updatedLyaerChoices = [];
    let indexOfCurrentLayer = values.arrayOfLayers.findIndex((a) => {
      console.log(a, asset.file.path.split("/")[2]);
      return a === asset.file.path.split("/")[2];
    });
    values.arrayOfLayers.forEach((item, index) => {
      if (!(index <= indexOfCurrentLayer)) {
        updatedLyaerChoices.push(item);
      }
    });
    console.log("Updated layers ", updatedLyaerChoices);
    setSlAsstData({ selectedAsset: asset, layersArray: updatedLyaerChoices });
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log("Value ", value, event);
    setSelectedItems(value);
  };

  useEffect(() => {
    let tempBlockListObJ = {};
    values.arrayOfLayers.forEach((layer) => {
      values.layersDict[layer].assets.forEach(
        (asset) => (tempBlockListObJ[asset.name] = [])
      );
    });
    if (Object.keys(values.blockListObject).length === 0) {
      methods.generateBlockListData();
    }
  }, [
    methods,
    values.arrayOfLayers,
    values.blockListObject,
    values.layersDict,
  ]);

  return (
    <>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <Header>
          <b>Step 5</b>
        </Header>
      </Grid>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <PaperNormal style={{ maxWidth: "50vw" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <BodyText style={{ fontWeight: "bold" }}>
                Create a block list.{" "}
              </BodyText>
              <BodyText style={{ display: "inline" }}>
                Here you can set rules for asset combinations. If there are
                items that should not go together you can define them in the
                block list. When creating your block list, start from base layer
                to last layer. Think of it as
              </BodyText>

              <BodyText style={{ display: "inline", fontWeight: "bold" }}>
                "Asset X{" "}
              </BodyText>
              <BodyText style={{ display: "inline" }}>
                in layer 1 should not go on top of
              </BodyText>
              <BodyText style={{ display: "inline", fontWeight: "bold" }}>
                {" "}
                Asset Y"{" "}
              </BodyText>
              <BodyText style={{ display: "inline" }}>in layer 2</BodyText>
            </Grid>
          </Grid>
        </PaperNormal>
      </Grid>
      <Grid
        container
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          margin: 15,
        }}
      >
        <Grid item xs={12}>
          <PaperDark style={{}}>
            <Grid container spacing={2}>
              {nftToolVaribales.arrayOfLayers?.length > 0 ? (
                <>
                  {nftToolVaribales.arrayOfLayers.map((l) => {
                    let assets = nftToolVaribales.layersDict[l].assets;
                    return (
                      <Grid item xs={12}>
                        <TextH6 style={{ fontWeight: "bold", color: "white" }}>
                          {l}
                        </TextH6>
                        <Grid item container xs={12}>
                          {assets.map((i, num) => (
                            <Grid item xs={2} key={num}>
                              <BlockListCard
                                i={i}
                                layer={l}
                                openModal={setOpenModal}
                                setSelectedItems={setSelectedItems}
                                //setSelectedAsset={setSelectedAsset}
                                setSelectedAsset={handleSelectAsset}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    );
                  })}
                </>
              ) : null}
            </Grid>
          </PaperDark>
        </Grid>
      </Grid>
      {/* Modal */}
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <PaperNormal style={style}>
          <Grid container>
            <Grid item xs={12}>
              <TextH6>
                <TextH6 style={{ display: "inline" }}>
                  Select Block Items for{" "}
                </TextH6>
                <TextH6 style={{ display: "inline", fontWeight: "bold" }}>
                  {slAsstData.selectedAsset?.name}
                </TextH6>
                <TextH6 style={{ display: "inline" }}> Asset </TextH6>
              </TextH6>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                paddingTop: 5,
              }}
            >
              <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-chip-label">
                  Assets to block
                </InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  fullWidth
                  value={selectedItems}
                  onChange={handleChange}
                  input={
                    <OutlinedInput
                      id="select-multiple-chip"
                      label="Assets to block"
                    />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {/* {values.arrayOfLayers.map((name) => { */}
                  {slAsstData?.layersArray.map((name) => {
                    let items = [];
                    let subHeader = (
                      <ListSubheader
                        style={{ color: "green", fontWeight: "bold" }}
                      >
                        {name}
                      </ListSubheader>
                    );
                    if (
                      name !==
                      slAsstData?.selectedAsset?.file?.path?.split("/")[2]
                    ) {
                      items.push(subHeader);
                      values.layersDict[name].assets.map((asset) => {
                        if (asset.name !== slAsstData?.selectedAsset?.name) {
                          let assetComponent = (
                            <MenuItem
                              key={asset.name}
                              value={asset.name}
                              style={getStyles(
                                asset.name,
                                selectedItems,
                                theme
                              )}
                            >
                              {asset.name}
                            </MenuItem>
                          );
                          items.push(assetComponent);
                        }
                      });
                    }

                    return items;
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Button
                color="error"
                size="small"
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                {" "}
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ justifyContent: "flex-end", display: "flex" }}
            >
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  methods.addToBlockList(
                    slAsstData?.selectedAsset?.name,
                    selectedItems
                  );
                  setOpenModal(false);
                }}
              >
                {" "}
                Submit
              </Button>
            </Grid>
          </Grid>
        </PaperNormal>
      </Modal>
      {/* FOOTER */}
      <Grid
        item
        xs={6}
        style={{ display: "flex", justifyContent: "flex-end", padding: 15 }}
      >
        <Button
          variant="outlined"
          style={{ color: "red" }}
          onClick={() => methods.setStep(4)}
        >
          Back
        </Button>
      </Grid>

      <Grid
        item
        xs={6}
        style={{ display: "flex", justifyContent: "flex-start", padding: 15 }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            methods.setStep(6);
          }}
        >
          Next
        </Button>
      </Grid>
    </>
  );
};

export default Step5;
