import React from "react";
import Typography from "@mui/material/Typography";

export const TextH6 = ({ children, style }) => (
  <Typography variant="h6" component="div" style={{ ...style }}>
    {children}
  </Typography>
);

export default TextH6;
