import Modal from "@mui/material/Modal";
import { BodyText, PaperNormal } from "../../../../common/components";
import { useSelector } from "react-redux";

import HelpModal from "./HelpModal";
import ExitModal from "./ExitModal";
import { useUtilsDashboardView } from "../../Hooks/useUtils";

export const AlertModal = () => {
  const { nftToolMethods } = useUtilsDashboardView();
  const containernModal = useSelector(
    (state) => state.containerReducer.containerModal
  );
  const modalType = useSelector((state) => state.containerReducer.modalType);
  const renderContent = () => {
    if (modalType === "help") {
      return <HelpModal />;
    }
    if (modalType === "exit") {
      return <ExitModal />;
    }
  };
  return (
    <>
      <Modal
        open={containernModal}
        onClose={() => {
          nftToolMethods.setContainerModal({ open: false, type: "" });
        }}
      >
        {renderContent()}
      </Modal>
    </>
  );
};

export default AlertModal;
