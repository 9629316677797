import { useState, useEffect, useLayoutEffect } from "react";
import Grid from "@mui/material/Grid";

import { useUtilsDashboardView } from "../Hooks/useUtils";
import { GuardSpinner } from "react-spinners-kit";
const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  justifyContent: "center",
};

const img = {
  objectFit: "contain",
  width: "100%",
};

export const PreviewNft = () => {
  const { nftToolMethods, nftToolVaribales } = useUtilsDashboardView();
  const values = nftToolVaribales;
  const methods = nftToolMethods;

  return (
    <Grid
      container
      item
      xs={12}
      style={{
        display: "flex",
        justifyContent: "center",
        padding: 10,
        maxWidth: "100%",
        maxHeight: "100%",
      }}
    >
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          padding: 2,
          //   border: "1px solid",
        }}
      >
        <div style={thumbInner}>
          {values.loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <GuardSpinner size={30} color="#686769" loading={true} />
            </div>
          ) : (
            <img src={values.tempPicture} style={img} />
          )}
        </div>
      </Grid>
    </Grid>
  );
};
export default PreviewNft;
