import * as React from "react";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import { BodyText, Header, PaperNormal, PaperDark } from "../../../../common";
import FolderStructure from "../../../../common/assets/folder-structure.png";
import { useUtilsDashboardView } from "../../Hooks/useUtils";
import { useDropzone } from "react-dropzone";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

import { GuardSpinner } from "react-spinners-kit";

export const Step1 = () => {
  const { nftToolMethods, nftToolVaribales } = useUtilsDashboardView();
  const values = nftToolVaribales;
  const methods = nftToolMethods;

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    multiple: true,
    onDrop: (acceptedFiles) => {
      // console.log("ON DROP -> ", acceptedFiles);
      methods.setLoading(true);
      let files = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      let layersDict = methods.generateLayerCategories(files);
      setTimeout(() => {
        let p = {
          layersDict: layersDict,
          files: files,
        };
        methods.loadImageData(p);
        // methods.setLayersDict(layersDict);
        // methods.setFiles(files);
        // methods.initializeData();
        // methods.InitializeLayers();
      }, 1000);
    },
  });

  return (
    <>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <Header>
          <b>Step 1</b>
        </Header>
      </Grid>
      <Grid
        container
        item
        spacing={2}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Grid item xs={6} style={{ display: "flex", justifyContent: "center" }}>
          <PaperNormal>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <BodyText>
                  In order to get started please upload a folder called images
                  that contains all the layers in sub folders. Please refer to
                  the image below for reference.
                </BodyText>
              </Grid>
            </Grid>
          </PaperNormal>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center", padding: 10 }}
        >
          <img src={FolderStructure} alt="folder structure" />
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PaperDark
            style={{
              border: "2px solid green",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <section>
              <div {...getRootProps({ className: "dropzone" })}>
                <input
                  {...getInputProps()}
                  type="file"
                  webkitdirectory=""
                  multiple
                  mozdirecto=""
                  directory=""
                />
                <BodyText
                  style={{
                    color: "white",
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "30px",
                    borderWidth: "2px",
                    borderRadius: "2px",
                    borderStyle: "dashed",
                    backgroundColor: "#585a62",
                    outline: "none",
                    transition: "border .24s ease-in-out",
                  }}
                >
                  Drag and drop the images folder here.
                </BodyText>
              </div>
            </section>
          </PaperDark>
        </Grid>
        <Grid item xs={12}>
          <PaperDark>
            {values.loading ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <GuardSpinner size={30} color="#686769" loading={true} />
              </div>
            ) : (
              <ImageList cols={10} style={{ padding: 2 }}>
                {values.files.map((i, num) => (
                  <ImageListItem key={num}>
                    <img
                      src={`${i.preview}`}
                      srcSet={`${i.preview}`}
                      loading="lazy"
                      style={{
                        objectFit: "contain",
                        border: "1px dashed black",
                      }}
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            )}
          </PaperDark>
        </Grid>
        {/* <Grid
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: "center",
            padding: 20,
          }}
        >
          <Button
            variant="outlined"
            disabled={!values.files.length > 0}
            onClick={() => {
              methods.setStep(5);
            }}
          >
            Express Check Out
          </Button>
        </Grid> */}
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            padding: 20,
          }}
        >
          <Button
            variant="outlined"
            disabled={!values.files.length > 0}
            onClick={() => {
              methods.setStep(2);
            }}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Step1;
