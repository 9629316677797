import { BodyText, PaperNormal } from "../../../../common/components";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useUtilsDashboardView } from "../../Hooks/useUtils";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
};

const paperStyle = {
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  backgroundColor: "#26282E",
};

export const ExitModal = () => {
  const { nftToolMethods } = useUtilsDashboardView();
  return (
    <Grid container spacing={2} style={style}>
      <Grid item xs={12}>
        <PaperNormal style={paperStyle}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3
                style={{
                  display: "inline",
                  color: "#FCFCFC",
                  fontWeight: "bold",
                }}
              >
                Are You Sure?
              </h3>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p style={{ display: "inline", color: "#FCFCFC" }}>
                When you exit the builder all your data and progress will be
                lost. Do you still want to exit?
              </p>
            </Grid>
            <Grid item xs={6}>
              <Button
                style={{ textTransform: "none", color: "red" }}
                onClick={() => {
                  nftToolMethods.setContainerModal({ open: false, type: "" });
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button href="/" style={{ textTransform: "none" }}>
                Continue
              </Button>
            </Grid>
          </Grid>
        </PaperNormal>
      </Grid>
    </Grid>
  );
};
export default ExitModal;
