import { useDispatch, useSelector } from "react-redux";
import { toolActions, gen_toolActions } from "../reducer";

export const useUtilsStep7 = () => {
  const nftToolVaribales = useSelector(({ toolReducer }) => toolReducer);

  const dispatch = useDispatch();

  function factorial(n) {
    let nums = Array.from({ length: n }, (_, i) => i + 1);
    console.log(nums.reduce((p, a) => p * a));
    return nums.reduce((p, a) => p * a);
  }

  const cartesian = (a) => {
    let res = a.reduce((a, b) =>
      a.flatMap((d) =>
        b.map((e) => {
          return [d, e].flat();
        })
      )
    );
    return res;
  };

  const nftToolMethods = {
    setLoading: (x) => {
      console.log("Set loading viewer", x);
      dispatch(toolActions.setLoading(x));
    },
    setStep: (x) => {
      dispatch(toolActions.setStep(x));
    },
    setName: (x) => {
      dispatch(toolActions.setName(x));
    },
    setDescription: (x) => {
      dispatch(toolActions.setDescription(x));
    },
    setNumber: (x) => {
      dispatch(toolActions.setNumber(x));
    },
    setSnackBar: (x) => {
      // X = {open: bool, message: ''}
      dispatch(gen_toolActions.setSnackBar(x));
    },
    setSnackBarOpen: (x) => {
      // x = bool
      dispatch(gen_toolActions.setSnackBarOpen(x));
    },
  };

  return {
    nftToolVaribales,
    nftToolMethods,
  };
};
