import { useDispatch, useSelector } from "react-redux";
import { toolActions } from "../reducer/toolReducer";

export const useUtilsStep6 = () => {
  // const nftToolVaribales = useSelector(({ toolReducer }) => toolReducer);

  const r_name = useSelector(({ toolReducer }) => toolReducer.name);
  const r_description = useSelector(
    ({ toolReducer }) => toolReducer.description
  );
  const r_number = useSelector(({ toolReducer }) => toolReducer.number);
  const r_loading = useSelector(({ toolReducer }) => toolReducer.loading);
  const dispatch = useDispatch();
  const r_blockListObject = useSelector(
    ({ toolReducer }) => toolReducer.blockListObject
  );
  const r_arrayOfLayers = useSelector(
    ({ toolReducer }) => toolReducer.arrayOfLayers
  );
  const r_layersDict = useSelector(({ toolReducer }) => toolReducer.layersDict);

  function factorial(n) {
    let nums = Array.from({ length: n }, (_, i) => i + 1);
    console.log(nums.reduce((p, a) => p * a));
    return nums.reduce((p, a) => p * a);
  }

  const cartesian = (a) => {
    let res = a.reduce((a, b) =>
      a.flatMap((d) =>
        b.map((e) => {
          return [d, e].flat();
        })
      )
    );
    return res;
  };

  const nftToolMethods = {
    setLoading: (x) => {
      dispatch(toolActions.setLoading(x));
    },
    setStep: (x) => {
      dispatch(toolActions.setStep(x));
    },
    setName: (x) => {
      dispatch(toolActions.setName(x));
    },
    setDescription: (x) => {
      dispatch(toolActions.setDescription(x));
    },
    setNumber: (x) => {
      dispatch(toolActions.setNumber(x));
    },
    setCollectionData: (x) => {
      dispatch(toolActions.setCollectionData(x));
    },

    calcMax_2: (x) => {
      console.log("Calculating block combinations ");
      //   console.log("Block List ", r_blockListObject);
      const assetLayerRef = {};
      const blockList = r_blockListObject;
      // Create asset to layer reference dictionary
      r_arrayOfLayers.forEach((l) => {
        r_layersDict[l].assets.forEach((a) => {
          // console.log("a ", a);
          assetLayerRef[a.name] = l;
        });
      });

      let blockCount = 0;
      let blf = [];
      // console.log("Block list ", blockList);
      Object.keys(blockList).forEach((item) => {
        if (blockList[item].length > 0) {
          let blockFrame = {};
          // get values for key
          let l = assetLayerRef[item];
          if (!(l in blockFrame)) {
            blockFrame[l] = [item];
          } else {
            let cur = blockFrame[l];
            cur.push(item);
            blockFrame[l] = cur;
          }
          // get values for values of key
          blockList[item].forEach((bli) => {
            let l = assetLayerRef[bli];
            if (!(l in blockFrame)) {
              blockFrame[l] = [bli];
            } else {
              let cur = blockFrame[l];
              cur.push(bli);
              blockFrame[l] = cur;
            }
          });
          blf.push(blockFrame);
        }
      });
      // console.log("BLF", blf);
      blf.forEach((bf) => {
        let keys = r_arrayOfLayers;
        let count = 1;
        // console.log(bf, keys);
        keys.forEach((k) => {
          if (k in bf) {
            if (bf[k].length > 0) {
              count *= bf[k].length;
            }
          } else {
            count *= r_layersDict[k].assets.length;
          }

          //   console.log("COUNT ", count);
        });
        blockCount += count;
      });
      // console.log("Block count ", blockCount);
      return nftToolMethods.calculateAllCombinations() - blockCount;
    },

    calculateMaximumUniqueCombinations: (x) => {
      /**
       * This method is replaced with above calcMax_2
       *
       * 1. No Block List
       * Take the product of the lenght of each layer. This will give all unique combinations without repitition
       *
       * 2. With Block List
       * Find the pair that is blocked
       *
       */
      console.log("Calculating block combinations ");
      //   console.log("Block List ", r_blockListObject);
      const assetLayerRef = {};
      const blockList = r_blockListObject;
      // Create asset to layer reference dictionary
      r_arrayOfLayers.forEach((l) => {
        r_layersDict[l].assets.forEach((a) => {
          // console.log("a ", a);
          assetLayerRef[a.name] = l;
        });
      });

      let assets = Object.keys(blockList);
      let allBlocked = [];
      let countOfBlockCombinations = 1;
      let blockFrame = {};
      // Find all combinations of blocked assets
      assets.forEach((a) => {
        if (blockList[a].length > 0) {
          allBlocked.push(a);
          allBlocked = allBlocked.cr_oncat(blockList[a]);
        }
      });
      console.log(allBlocked);
      allBlocked.forEach((a) => {
        let layer = assetLayerRef[a];
        let value = a;
        if (!(layer in blockFrame)) {
          blockFrame[layer] = [value];
        } else {
          let cur = blockFrame[layer];
          blockFrame[layer] = [...cur, value];
        }
      });

      // Count assets per layer
      Object.keys(blockFrame).forEach((k) => {
        countOfBlockCombinations *= blockFrame[k].length;
      });
      //   console.log("Count of block combinations", countOfBlockCombinations);
      return (
        nftToolMethods.calculateAllCombinations() - countOfBlockCombinations
      );
    },
    calculateAllCombinations: (x) => {
      let input = r_arrayOfLayers.map((l) => {
        return r_layersDict[l].assets.map((a) => {
          return a.name;
        });
      });

      let product = 1;
      input.forEach((a) => (product *= a.length));
      console.log("Product ", product);
      return product;
    },
    cartisianProductGeneration: (x) => {
      let input = r_arrayOfLayers.map((l) => {
        return r_layersDict[l].assets.map((a) => {
          return a.name;
        });
      });
      let output = cartesian(input);
      console.log("Out Put", output.length);
    },
  };

  return {
    // nftToolVaribales,
    nftToolMethods,
    r_name,
    r_description,
    r_number,
    r_loading,
  };
};

/**
 * Sample 
 *       //   let input = [
      //     ["a1", "a2", "a3", "a4"],
      //     ["b1", "b2", "b3"],
      //     ["c1", "c2", "c3", "c4", "c5", "c6", "c7", "c8", "c9", "c10", "c11"],
      //     ["d1", "d2", "d3"],
      //     ["e1", "e2", "e3"],
      //     ["f1", "f2", "f3"],
      //     ["g1", "g2", "g3"],
      //     ["h1", "h2", "h3"],
      //     ["i1", "i2", "i3"],
      //   ];
      //   let output = cartesian(input);
      //   console.log("Out Put", output.length);
 */

/**
       * if (blockList[a].length > 0) {
          console.log("----------------------");
          console.log("Asset with constraints ", a);
          // Get layers that are blocked
          let blockedLayers = blockList[a].map((i) => {
            return assetLayerRef[i];
          });

          console.log("Blocked layers ", blockedLayers);
          // Iterate through layers dict and get product of layer length that is not blocked
          let layerProduct = 1;
          nftToolVaribales.arrayOfLayers.map((l) => {
            if (!blockedLayers.includes(l)) {
              layerProduct *= nftToolVaribales.layersDict[l].assets.length;
            }

            countOfBlockCombinations += layerProduct;
          });
        }
       */
