import HeaderComponent from "./Header";

export const LandingPageContainer = () => {
  return (
    <>
      <HeaderComponent />
    </>
  );
};

export default LandingPageContainer;

//  new comment
