import Grid from "@mui/material/Grid";
import { PaperNormal } from "../../../common";
import { useUtilsDashboardView } from "../Hooks/useUtils";
import { GuardSpinner } from "react-spinners-kit";
import Typography from "@mui/material/Typography";

import Link from "@mui/material/Link";

export const PreviewNftAndMetaData = (p) => {
  const { nftToolMethods, nftToolVaribales } = useUtilsDashboardView();

  const values = nftToolVaribales;

  let loading = p.loading;

  const renderPreviewImages = () => {
    if (values.previewImages.length === 1) {
      return values.previewImages.map((i) => (
        <img
          alt="Preview"
          src={i}
          style={{ object: "fit", width: "80%", height: "80%" }}
        />
      ));
    } else {
      return <></>;
    }
  };
  const renderMetaData = () => {
    if (values?.metaData?.length === 1) {
      return values.metaData.map((m) => (
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={12}>
            <PaperNormal>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <pre>{JSON.stringify(m, null, 2)}</pre>
              </div>
            </PaperNormal>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              Remember that the ipfs link and the policy id are place holders
              for testing purposes
            </Typography>
          </Grid>
        </Grid>
      ));
    } else {
      return <></>;
    }
  };
  const renderPreviewTitle = () => {
    if (values?.previewImages.length === 1) {
      return (
        <>
          <Typography variant="h4">Preview NFT image and metadata</Typography>
        </>
      );
    } else {
      return (
        <div style={{ textAlign: "center" }}>
          <h4 style={{ color: "blue" }}>Click Generate preview below</h4>
        </div>
      );
    }
  };

  const renderContent = () => {
    return (
      <>
        <Grid item xs={12}>
          <>
            {renderPreviewTitle()}
            <div>
              {values?.metaData?.length > 0 ? (
                <>
                  Test the metadata at{" "}
                  <Link
                    href="https://pool.pm/test/metadata"
                    target="_blank"
                    rel="noopener"
                  >
                    pool.pm/test/metadata
                  </Link>{" "}
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 15,
          }}
        >
          <div style={{ maxWidth: "50%", maxHeight: "100%" }}>
            {renderPreviewImages()}
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 15,
          }}
        >
          <>{renderMetaData()}</>
        </Grid>
      </>
    );
  };
  return <>{renderContent()}</>;
};
